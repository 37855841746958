import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./OurFacilites.css";
import { Container, Row, Col } from "react-bootstrap";
import Date_Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Date_data, GALLERY_DATA } from "../../constants/AccountData";
import "react-rangeslider/lib/index.css";
import images from "../../constants/images";

import SignUpModal from '../../constants/SignUpModal/SignUpModal'

export default class OurFacilites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      r: 0,
      show_modal:false,
    };
  }

  signupmodale =()=>
  {
    this.setState({show_modal:true});
  }
  sign_modal =()=>
  {
    
    this.setState({show_modal:false});
  }
  render() {
    const { show_modal } = this.state;
    const Date_slider = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 10,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };

    const Addional_Rooms_Data = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };
    return (
      <section className="our_facilites_section" id="our_facilites_section">
        <Header />
      <SignUpModal  show={show_modal} handleClose={this.sign_modal}/>
        <section
          className="our_facilites_wrap common_top_padding"
          id="our_facilites_wrap"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="our_facilites_title">
                  <h1>
                    OUR <span>ACTIVITIES</span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our_facilites_about" id="our_facilites_about">
          <Container>
            <Row>
              <Col md={5} xl={6}>
                <div className="our_facilites_image">
                  <img
                    src={images.slam_logo}
                    alt="dabblefit"
                    className="slam_logo"
                  />
                  <span></span>
                </div>

                <div className="our_facilites_location">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <h3>
                    <span>RK</span> Salai
                  </h3>
                </div>

                <div className="our_facilites_description">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and
                  </p>
                </div>

                <div className="d-none d-sm-block">
                  <div className="our_facilites_icons">
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.parking}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Parking</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.shower}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Shower</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.locker}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Locker</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.cctv}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Cctv</h6>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={7} xl={6} className="our_facilities_slider">
                <Date_Slider {...Addional_Rooms_Data}>
                  {(Date_data || []).map((x, index) => {
                    return (
                      <div className="our_facilites_image_wrapper">
                        <img
                          src={images.workout_banner}
                          className="workout_banner"
                          alt="dabblefit"
                        />
                      </div>
                    );
                  })}
                </Date_Slider>
              </Col>

              <Col md={5}>
                <div className="d-block d-sm-none">
                  <div className="our_facilites_icons">
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.parking}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Parking</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.shower}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Shower</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.locker}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Locker</h6>
                    </div>
                    <div className="our_facliites_inner_icons">
                      <img
                        src={images.cctv}
                        alt="dabblefit"
                        className="slam_logo"
                      />
                      <h6>Cctv</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our_date_section">
          <Container>
            <Row>
              <Col md={2} className="our_date_title">
                <div>
                  <h6>Date</h6>
                </div>
              </Col>
              <Col md={10} xs={12}>
                <Date_Slider {...Date_slider}>
                  {(Date_data || []).map((x, index) => {
                    return (
                      <div className="date_wrapper_list">
                        <div className="date_wraper_inner_list">
                          <h6>{x.date_number}</h6>
                          <h6>{x.date_month}</h6>
                        </div>
                      </div>
                    );
                  })}
                </Date_Slider>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="our_facilities_card_wrap"
          id="our_facilities_card_wrap"
        >
          <Container>
            <Row>
              {(GALLERY_DATA || []).map((x, index) => {
                return (
                  <Col md={6}>
                    <div className="our_facilities_card_background">
                      <Row>
                        <Col md={3} xs={3}>
                          <div className="our_image_container">
                            <img
                              src={images.workout}
                              alt="dabblefit"
                              className=""
                            />
                          </div>
                        </Col>
                        <Col md={9} xs={9}>
                          <div className="our_facilities_content">
                            <div className="our_activity_container">
                              <div className="our_activity_conent">
                                <p>Activity:</p>
                                <span>gym</span>
                              </div>
                              <div className="our_difficulty_container">
                                <h6>difficulty</h6>
                                <span>Hard</span>
                              </div>
                            </div>

                            <div className="our_clock_container">
                              <div className="our_clock_wrapper">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                <span>60 mins</span>
                              </div>
                              <div className="our_clock_content">
                                <h6>
                                  500 <span>KCAL</span>
                                </h6>
                              </div>
                            </div>
                            <div className="d-none d-sm-block">
                              <div className="our_clock_timing">
                                <div>
                                  <span>5:30 PM</span>
                                </div>
                                <div>
                                  <span>6:30 PM</span>
                                </div>
                                <div>
                                  <span>7:30 PM</span>
                                </div>
                                <div>
                                  <span>8:30 PM</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={12} className="d-block d-sm-none">
                          <div className="our_clock_timing ">
                            <div>
                              <span>5:30 PM</span>
                            </div>
                            <div>
                              <span>6:30 PM</span>
                            </div>
                            <div>
                              <span>7:30 PM</span>
                            </div>
                            <div>
                              <span>8:30 PM</span>
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div>
                            <div className="our_view_section">
                              <p>View All</p>
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="our_benifits_content">
                            <h6>Benefits</h6>
                            <ul>
                              <li>
                                orem Ipsum is simply dummy text of orem Ipsum is
                                simply dummy
                              </li>
                              <li>
                                orem Ipsum is simply dummy text of orem Ipsum is
                                simply dummy
                              </li>
                            </ul>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="our_price_content">
                            <div className="our_price_wrapper">
                              <p>price</p>
                              <span>&#8377; 400</span>
                            </div>

                            <div className="our_book_button">
                              <span onClick={this.signupmodale}>Book</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>

        <section className="disclaimer_section" id="disclaimer_section">
          <Container>
            <Row>
              <Col md={12} className="disclaimer_card">
                <Row>
                  <Col md={12}>
                    <div className="disclaimer_title">
                      <h6>THIS FACILITY POLICY</h6>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <div className="disclaimer_description">
                      <ul>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </section>
    );
  }
}
