import React, { Component } from "react";

import { Row, Col, Form, Table, Button } from "react-bootstrap";
import "./Profile.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Moment from "moment";
import { connect } from "react-redux";
import * as ProviderActions from "../../redux/actions/Provider";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DropzoneDialog } from "material-ui-dropzone";

const Reservation_data = [
  {
    id: 1,
    time_data: "5 Minitues",
  },
  {
    id: 2,
    time_data: "10 Minitues",
  },
  {
    id: 3,
    time_data: "15 Minitues",
  },
];

const google = (window.google = window.google ? window.google : {});
class Profile extends Component {
  constructor(props) {
    super(props);
    this.handlePlaceSelect1 = this.handlePlaceSelect1.bind(this);
    this.autocomplete1 = null;

    this.state = {
      close_date: new Date(),
      date_array_list: [],

      cover_imagePreviewUrl: "https://wallpapercave.com/wp/wp2356164.jpg",
      cover_image_address: "https://wallpapercave.com/wp/wp2356164.jpg",
      files: [],
      multiple_upload_modal: false,

      image_address: [],
      imagePreviewUrl: [],
      image_ids: [],
      image_title: [],
      image_id: 0,
      location_id: this.props.studio_object_data.id,
      from_state: "",
      from_city: "",
      from_area: "",
      from_building_address: "",
      from_street_address: "",
      from_pincode: "",
      from_place_id: "",

      amenity_list: [],
      activity_list: [],
      clearness_list: [],

      amenity_data: [],
      activity_data: [],
      clearness_data: [],

      phone_number: "",
      website: "",
      facebook: "",
      instagram: "",
      twitter: "",
      yelp: "",
      reservation_deadline: "1",
      description: "",
    };
  }

  componentDidMount() {
    this.autocomplete1 = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete1"),
      {}
    );
    this.autocomplete1.addListener("place_changed", this.handlePlaceSelect1);

    setTimeout(() => {
      this.props.dispatch(ProviderActions.getCategoryList("GAM"));
      this.setState({ amenity_list: this.props.category_list_data });
      setTimeout(() => {
        this.props.dispatch(ProviderActions.getCategoryList("GC"));
        this.setState({ clearness_list: this.props.category_list_data });
        setTimeout(() => {
          this.studioObjectLoading();
        }, 1000);
      }, 1000);
    }, 1000);
  }

  componentDidUpdate = () => {
    if (this.props.success_message === "STUDIO_MULTI_IMAGE_UPLOAD") {
      this.props.dispatch(ProviderActions.GetStudioData());
      this.props.dispatch(ProviderActions.empty_message());
      toast.success("Successfully updated ..");
    } else if (this.props.success_message === "STUDIO_OBJECT_CHANGE_SUCCESS") {
      this.props.dispatch(ProviderActions.empty_message());
      this.studioObjectLoading();
    } else if (this.props.success_message === "STUDIO_DATA_SUCCESS") {
      toast.success("Successfully Submitted..");
      this.props.dispatch(ProviderActions.GetStudioData());
      this.props.dispatch(ProviderActions.empty_message());
    }
  };

  studioObjectLoading = () => {
    let image_array = this.props.images_address_list;
    let locations_id = this.props.studio_object_data.branch_location;
    let branch_image_array = image_array[`${locations_id}`];
    let branch_array = [];
    let image_count = 0;
    let image_ids = [];
    for (let i = 0; i < branch_image_array.length; i++) {
      branch_array.push([branch_image_array[i].image_address]);
      image_ids.push(i);
      image_count = image_count + 1;
    }
    this.setState({
      imagePreviewUrl: branch_array,
      image_id: image_count,
      image_ids: image_ids,
    });
    this.setState({
      cover_image_address: this.props.studio_object_data.cover_image,
    });

    let amenity_data = this.state.amenity_list;
    let arr = this.props.studio_object_data.ameneties;
 
    if ((arr !== "undefined") || (arr.toString() !="null") ) {
      if (arr == arr.includes(",")) {
        let arr1 = arr.split(",");
        for (let i = 0; i < amenity_data.length; i++) {
          for (let j = 0; j < arr1.length; j++) {
            if (arr1[j] === amenity_data[i]["key"]) {
              amenity_data[i]["status"] = "0";
              this.setState({
                amenity_data: [
                  ...this.state.amenity_data,
                  amenity_data[i]["key"],
                ],
              });
              break;
            } else {
              amenity_data[i]["status"] = "1";
            }
          }
        }
      } else {
        for (let i = 0; i < amenity_data.length; i++) {
          if (arr === amenity_data[i]["key"]) {
            amenity_data[i]["status"] = "0";
            this.setState({
              amenity_data: [
                ...this.state.amenity_data,
                amenity_data[i]["key"],
              ],
            });
            break;
          } else {
            amenity_data[i]["status"] = "1";
          }
        }
      }
    }
    this.setState({ amenity_list: amenity_data });

    let clearness_data = this.state.clearness_list;
    let arr1 = this.props.studio_object_data.clearness;
    if ((arr !== "undefined") || (arr.toString() !="null") ) {

      if (arr1 == arr1.includes(",")) {
        let arr11 = arr1.split(",");
        for (let i = 0; i < clearness_data.length; i++) {
          for (let j = 0; j < arr11.length; j++) {
            if (arr11[j] === clearness_data[i]["key"]) {
              clearness_data[i]["status"] = "0";
              this.setState({
                clearness_data: [
                  ...this.state.clearness_data,
                  clearness_data[i]["key"],
                ],
              });
              break;
            } else {
              clearness_data[i]["status"] = "1";
            }
          }
        }
      } else {
        for (let i = 0; i < clearness_data.length; i++) {
          if (arr1 === clearness_data[i]["key"]) {
            clearness_data[i]["status"] = "0";
            this.setState({
              clearness_data: [
                ...this.state.clearness_data,
                clearness_data[i]["key"],
              ],
            });
            break;
          } else {
            clearness_data[i]["status"] = "1";
          }
        }
      }
    }
    this.setState({ clearness_list: clearness_data });

    this.setState({ from_state: this.props.studio_object_data.state });
    this.setState({ from_city: this.props.studio_object_data.city });
    this.setState({ from_area: this.props.studio_object_data.area });

    this.setState({
      from_building_address: this.props.studio_object_data.building_address,
    });
    this.setState({
      from_street_address: this.props.studio_object_data.street_address,
    });
    this.setState({ from_pincode: this.props.studio_object_data.pincode });
    this.setState({ from_place_id: this.props.studio_object_data.place_id });

    this.setState({ website: this.props.studio_object_data.website });
    this.setState({ facebook: this.props.studio_object_data.facebook });
    this.setState({ instagram: this.props.studio_object_data.instagram });
    this.setState({ twitter: this.props.studio_object_data.twitter });
    this.setState({ yelp: this.props.studio_object_data.yelp });
    this.setState({
      reservation_deadline: this.props.studio_object_data.reservation_deadline,
    });
    this.setState({ description: this.props.studio_object_data.description });
    this.setState({ phone_number: this.props.studio_object_data.phone_number });
    this.setState({
      reservation_deadline: this.props.studio_object_data.reservation_deadline,
    });
  };

  onchange_Amenity_Checkbox_Handler = (d) => {
    let amenity_data = this.state.amenity_list;
    let arr1 = this.state.amenity_data;
    for (let i = 0; i < amenity_data.length; i++) {
      if (amenity_data[i]["key"] === d.key) {
        if (amenity_data[i]["status"] === "0") {
          amenity_data[i]["status"] = "1";
          for (let j = 0; j < arr1.length; j++) {
            if (arr1[j] === d.key) {
              if (j > -1) {
                arr1.splice(j, 1);
                this.setState({
                  amenity_data: arr1,
                });
              }
            }
          }
        } else {
          amenity_data[i]["status"] = "0";
          this.setState({
            amenity_data: [...this.state.amenity_data, d.key],
          });
        }
      }
    }
    this.setState({
      amenity_list: amenity_data,
    });

    // alert(this.state.amenity_data)
  };

  onchange_Clearness_Checkbox_Handler = (d) => {
    let clearness_data = this.state.clearness_list;
    let arr1 = this.state.clearness_data;
    for (let i = 0; i < clearness_data.length; i++) {
      if (clearness_data[i]["key"] === d.key) {
        if (clearness_data[i]["status"] === "0") {
          clearness_data[i]["status"] = "1";
          for (let j = 0; j < arr1.length; j++) {
            if (arr1[j] === d.key) {
              if (j > -1) {
                arr1.splice(j, 1);
                this.setState({
                  clearness_data: arr1,
                });
              }
            }
          }
        } else {
          clearness_data[i]["status"] = "0";
          this.setState({
            clearness_data: [...this.state.clearness_data, d.key],
          });
        }
      }
    }
    this.setState({
      clearness_list: clearness_data,
    });
    // alert(this.state.clearness_data)
  };

  handlePlaceSelect1() {
    let addressObject = this.autocomplete1.getPlace();
    console.log(addressObject.place_id);
    let address = addressObject.address_components;
    this.setState({
      from_place_id: addressObject.place_id,
      from_building_address: "",
      from_street_address: "",
      from_area: "",
      from_city: "",
      from_state: "",
      from_pincode: "",
    });
    if (address.length === 10) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[1].long_name,
        from_area: address[4].long_name,
        from_city: address[5].long_name,
        from_state: address[7].long_name,
        from_pincode: address[9].long_name,
      });
    } else if (address.length === 9) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[1].long_name,
        from_area: address[3].long_name,
        from_city: address[4].long_name,
        from_state: address[6].long_name,
        from_pincode: address[8].long_name,
      });
    } else if (address.length === 8) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[1].long_name,
        from_area: address[3].long_name,
        from_city: address[4].long_name,
        from_state: address[5].long_name,
        from_pincode: address[7].long_name,
      });
    } else if (address.length === 7) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[1].long_name,
        from_area: address[2].long_name,
        from_city: address[3].long_name,
        from_state: address[4].long_name,
        from_pincode: address[6].long_name,
      });
    } else if (address.length === 6) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[1].long_name,
        from_area: address[2].long_name,
        from_city: address[3].long_name,
        from_state: address[4].long_name,
        from_pincode: address[6].long_name,
      });
    } else if (address.length === 5) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[0].long_name,
        from_area: address[1].long_name,
        from_city: address[1].long_name,
        from_state: address[2].long_name,
      });
    } else if (address.length === 4) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[0].long_name,
        from_area: address[1].long_name,
        from_city: address[2].long_name,
        from_state: address[1].long_name,
      });
    } else if (address.length === 3) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[0].long_name,
        from_area: address[0].long_name,
        from_city: address[1].long_name,
        from_state: address[2].long_name,
      });
    } else if (address.length === 2) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[0].long_name,
        from_area: address[0].long_name,
        from_city: address[1].long_name,
      });
    } else if (address.length === 1) {
      this.setState({
        from_building_address: `${address[0].long_name}`,
        from_street_address: address[0].long_name,
      });
    } else {
    }
  }

  Onchange_Date_Function = (date) => {
    this.setState({ close_date: date });
  };

  add_date_time_function = () => {
    var { close_date, date_array_list } = this.state;
    var new_object = {
      close_date: close_date,
    };
    date_array_list.push(new_object);
    this.setState({ date_array_list: date_array_list });
  };

  remove_date_time_function = (index) => {
    const { date_array_list } = this.state;
    date_array_list.splice(index, 1);
    this.setState({ date_array_list: date_array_list });
  };

  onFileChangeHandler = (e) => {
    e.preventDefault();
    this.setState({ location_id: this.props.studio_object_data.id });
    this.setState({ cover_image_address: e.target.files[0] });
    let selectorFiles = e.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(selectorFiles);
    reader.onloadend = () => {
      this.setState({
        cover_imagePreviewUrl: reader.result,
      });
    };

    const Authorization = localStorage.getItem("Authorization");
    let location_id = this.props.studio_object_data.id;
    const form_Data1 = JSON.stringify({ Authorization, location_id });
    const formData = new FormData();

    formData.append("image_address", selectorFiles);
    formData.append("json", form_Data1);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    console.log(this.state.cover_image_address);
    console.log(JSON.stringify(formData));

    this.props.dispatch(ProviderActions.studioCoverImageUpload(formData));
  };

  handleSave(files) {
    this.setState({
      files: files,
      multiple_upload_modal: false,
    });

    this.setState({
      image_address: [...this.state.image_address, ...files],
    });

    let image_count = this.state.image_id;
    image_count = image_count * 1;

    files.forEach((input) => {
      let selectorFiles = input;
      let selectorFile_name = input.name;
      let reader = new FileReader();
      reader.readAsDataURL(selectorFiles);
      reader.onloadend = () => {
        this.setState({
          imagePreviewUrl: [...this.state.imagePreviewUrl, reader.result],
        });
        this.setState({
          image_ids: [...this.state.image_ids, image_count],
        });
        this.setState({
          image_title: [...this.state.image_title, selectorFile_name],
        });
      };
      image_count = image_count + 1;
    });

    this.setState({ image_id: image_count });
  }

  delete_handler_function = (index) => {
    let Image_heading = this.state.image_title;
    let Image_location = this.state.imagePreviewUrl;
    let Image_Address = this.state.image_address;
    let image_ids = this.state.image_ids;
    for (let i = Image_heading.length; i--; ) {
      if (Image_heading[i] === Image_heading[index]) {
        Image_heading.splice(i, 1);
      }
    }
    for (let i = Image_location.length; i--; ) {
      if (Image_location[i] === Image_location[index]) {
        Image_location.splice(i, 1);
      }
    }
    for (let i = Image_Address.length; i--; ) {
      if (Image_Address[i] === Image_Address[index]) {
        Image_Address.splice(i, 1);
      }
    }
    for (let i = image_ids.length; i--; ) {
      if (image_ids[i] === image_ids[index]) {
        image_ids.splice(i, 1);
      }
    }
    this.setState({
      imagePreviewUrl: Image_location,
      image_address: Image_Address,
      image_ids: image_ids,
    });
  };

  handleSubmitImageData = (event) => {
    event.preventDefault();
    let location_id = this.props.studio_object_data.id;
    let { image_ids } = this.state;
    let { image_title } = this.state;
    image_ids = image_ids.toString();
    image_title = image_title.toString();
    const Authorization = localStorage.getItem("Authorization");
    const form_Data1 = JSON.stringify({
      image_title,
      image_ids,
      Authorization,
      location_id,
    });

    const formData = new FormData();
    formData.append("json", form_Data1);

    let files = this.state.image_address;
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      formData.append("studio_images", files[i]);
    }

    this.props.dispatch(ProviderActions.studioImageUpload(formData));
  };

  multiple_upload_hide = () => {
    this.setState({ multiple_upload_modal: false });
  };
  multiple_upload_show = () => {
    this.setState({ multiple_upload_modal: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    console.log({ [name]: value });
  };

  reservationOnchange = (event) => {
    this.setState({ reservation_deadline: event.target.value });
  };

  add_submit_function = (e) => {
    e.preventDefault();
    const Authorization = localStorage.getItem("Authorization");

    const {
      from_state,
      from_city,
      from_area,
      from_building_address,
      from_street_address,
      from_pincode,
      from_place_id,

      phone_number,
      website,
      facebook,
      instagram,
      twitter,
      yelp,
      reservation_deadline,
      description,
    } = this.state;
    let location_id = this.props.studio_object_data.id;

    let { amenity_data, activity_data, clearness_data } = this.state;
    let ameneties = amenity_data.toString();
    let clearness = clearness_data.toString();

    const form_Data1 = JSON.stringify({
      Authorization,
      from_state,
      from_city,
      from_area,
      from_building_address,
      from_street_address,
      from_pincode,
      from_place_id,
      amenity_data,
      activity_data,
      clearness_data,
      phone_number,
      website,
      facebook,
      instagram,
      twitter,
      yelp,
      reservation_deadline,
      description,
      location_id,
      ameneties,
      clearness,
    });
    const formData = new FormData();
    formData.append("json", form_Data1);

    this.props.dispatch(ProviderActions.studioDataUpload(formData));

    console.log("**********");
    console.log(formData);
    console.log("***********");
  };

  render() {
    const { date_array_list } = this.state;
    return (
      <div className="profile_dashboard_section" id="profile_dashboard_section">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <div className="profile_dashboard_title">
              <h6>Profile</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} lg={12} xl={8}>
            <div className="profile_tab_wrapper">
              <Row>
                <Col md={12}>
                  <div className="profile_logo_card_wrapper">
                    <Row>
                      <Col md={6} xs={12}>
                        <div className="profile_logo_padding_cards">
                          <div className="profile_logo_content_title">
                            <h6>Add your Cover Image</h6>
                          </div>

                          <div className="profile_logo_image_container">
                            <img
                              src={this.state.cover_image_address}
                              className="img-fluid"
                              alt="dabblefit"
                            />
                            <h6>
                              Minimum Size Must be{" "}
                              <span> 1242 * 828 pixels</span>
                            </h6>
                          </div>

                          <Button
                            type="button"
                            size="md"
                            color="success"
                            style={{ height: "40px" }}
                          >
                            <label htmlFor="image_address">
                              <i className="fa fa-upload"></i> Upload
                            </label>
                            <input
                              hidden
                              id="image_address"
                              name="image_address"
                              type="file"
                              accept="image/*"
                              onChange={this.onFileChangeHandler}
                            />
                          </Button>
                        </div>
                      </Col>

                      <Col
                        md={6}
                        xs={12}
                        className="profile_review_card_wrapper"
                      >
                        <div className="profile_review_card_description">
                          <p>
                            Your cover photo will show up on your venue page on
                            the Classpass mobile app and website.
                          </p>
                        </div>

                        <div>
                          <img
                            src="https://www.nicepng.com/png/detail/443-4437721_responsive-website-design-web-mobile-responsive-png.png"
                            className="img-fluid"
                            alt="dabblefit"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="profile_logo_card_wrapper">
                    <Row>
                      <Col md={6} xs={12}>
                        <div className="profile_logo_padding_cards">
                          <div className="profile_logo_content_title">
                            <h6>Add your Banner Images</h6>
                          </div>

                          <div className="profile_logo_image_container">
                            <img
                              src="https://wallpapercave.com/wp/wp2356164.jpg"
                              className="img-fluid"
                              alt="dabblefit"
                            />
                            <h6>
                              Minimum Size Must be{" "}
                              <span> 1242 * 828 pixels</span>
                            </h6>
                          </div>

                          <div
                            className="profile_logo_upload_button"
                            onClick={this.multiple_upload_show}
                          >
                            <p>Upload photo</p>
                          </div>
                        </div>

                        <Row>
                          <Col md={12}></Col>
                        </Row>
                      </Col>

                      <Col
                        md={6}
                        xs={12}
                        className="profile_review_card_wrapper"
                      >
                        <div className="profile_logo_padding_cards">
                          <Form onSubmit={this.handleSubmitImageData}>
                            <Row>
                              {this.state.imagePreviewUrl.map((item, index) => {
                                return (
                                  <Col
                                    key={index}
                                    md={4}
                                    className="Preview_main_container_wrapper"
                                  >
                                    <Row>
                                      <Col
                                        md={8}
                                        xs={8}
                                        className="Preview_Main_Container"
                                      >
                                        <div className="Preview_Image_Container">
                                          <img
                                            src={item}
                                            alt="ss"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Col>

                                      <Col
                                        md={1}
                                        xs={1}
                                        className="Preview_Icon_Main"
                                      >
                                        <div className="Preview_Icon_Container">
                                          <i
                                            onClick={() => {
                                              this.delete_handler_function(
                                                index
                                              );
                                            }}
                                            className="fa fa-trash-o fa-1x"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                );
                              })}
                            </Row>
                            <div className="multiple_image_submit_wrapper">
                              <Row>
                                {this.state.imagePreviewUrl.length !== 0 && (
                                  <div className="multiple_image_submit_button">
                                    <Button
                                      className="multiple_image_button"
                                      type="submit"
                                      variant="danger"
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                )}
                              </Row>
                            </div>
                          </Form>
                        </div>
                        {/* <div className="profile_review_card_description">
                            <p>
                              Your cover photo will show up on your venue page
                              on the Classpass mobile app and website.
                            </p>
                          </div>

                          <div className="profile_review_card_mobile_wrapper">
                            <img
                              src="https://www.nicepng.com/png/detail/443-4437721_responsive-website-design-web-mobile-responsive-png.png"
                              className="img-fluid"
                              alt="dabblefit"
                            />
                          </div> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Form onSubmit={this.add_submit_function}>
                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <div className="profile_logo_padding_cards">
                        <div className="profile_logo_contact_content">
                          <h6>Location</h6>

                          <div>
                            <Row>
                              <Col md={12}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Search Here and Select Address"
                                      id="autocomplete1"
                                      name="autocomplete1"
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Building Address"
                                      id="from_building_address"
                                      name="from_building_address"
                                      value={this.state.from_building_address}
                                      onChange={this.handleChange}
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-home"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Street Address"
                                      id="from_street_address"
                                      name="from_street_address"
                                      value={this.state.from_street_address}
                                      onChange={this.handleChange}
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-address-book"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="City"
                                      id="from_city"
                                      name="from_city"
                                      value={this.state.from_city}
                                      onChange={this.handleChange}
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-building"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="State"
                                      id="from_state"
                                      name="from_state"
                                      value={this.state.from_state}
                                      onChange={this.handleChange}
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-home"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="contact_dashboard_form_wrapper">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Pincode"
                                      id="from_pincode"
                                      name="from_pincode"
                                      value={this.state.from_pincode}
                                      onChange={this.handleChange}
                                    />
                                    <div className="contact_dashboard_form_icons provider_icons_color">
                                      <i
                                        className="fa fa-location-arrow"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_contact_content">
                              <h6>Contacts</h6>

                              <div>
                                <Row>
                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Phone number"
                                          name="phone_number"
                                          value={this.state.phone_number}
                                          onChange={this.handleChange}
                                          maxLength={10}
                                          minLength={10}
                                          
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Website URL"
                                          name="website"
                                          value={this.state.website}
                                          onChange={this.handleChange}
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-window-maximize"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Facebook URL"
                                          name="facebook"
                                          value={this.state.facebook}
                                          onChange={this.handleChange}
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-facebook-square"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="@ instagram"
                                          name="instagram"
                                          value={this.state.instagram}
                                          onChange={this.handleChange}
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-instagram"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="@ twitter"
                                          name="twitter"
                                          value={this.state.twitter}
                                          onChange={this.handleChange}
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-twitter"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="contact_dashboard_form_wrapper">
                                      <Form.Group controlId="formBasicPassword">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Yelp URL"
                                          name="yelp"
                                          value={this.state.yelp}
                                          onChange={this.handleChange}
                                        />
                                        <div className="contact_dashboard_form_icons">
                                          <i
                                            className="fa fa-yelp"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_venue_content">
                              <h6>Venue description</h6>

                              <p>Describe your renue and is facilities</p>
                            </div>

                            <div>
                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label></Form.Label>
                                <Form.Control
                                  name="description"
                                  value={this.state.description}
                                  onChange={this.handleChange}
                                  as="textarea"
                                  rows={3}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_venue_content">
                              <h6>Saftey &#38; Clearness</h6>

                              <div>
                                <Col md="12">
                                  {(this.state.clearness_list || []).map(
                                    (sc, index1) => (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            key={sc.key}
                                            value={sc.key}
                                            name={sc.value}
                                            checked={sc.status === "0"}
                                          />
                                        }
                                        label={sc.value}
                                        onChange={() => {
                                          this.onchange_Clearness_Checkbox_Handler(
                                            sc
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                </Col>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_venue_content">
                              <h6>Amentites</h6>

                              <div className="profile_logo_amentites">
                                <Row>
                                  <Col md="12">
                                    {(this.state.amenity_list || []).map(
                                      (ga, index1) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              key={ga.key}
                                              value={ga.key}
                                              name={ga.value}
                                              checked={ga.status === "0"}
                                            />
                                          }
                                          label={ga.value}
                                          onChange={() => {
                                            this.onchange_Amenity_Checkbox_Handler(
                                              ga
                                            );
                                          }}
                                        />
                                      )
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_reservation">
                              <h6>Reservation DeadLine</h6>

                              <p>
                                Set a deadline for when Dabblefit memebers need
                                to make
                              </p>
                            </div>

                            <Row>
                              <Col md={6}>
                                <div className="profile_time_dropdown_wrapper">
                                  <Form.Control
                                    className="profile_dropdown_container profile_time_dropdown"
                                    defaultValue="Budget..."
                                    value={this.state.reservation_deadline}
                                    as="select"
                                    onChange={this.reservationOnchange}
                                  >
                                    {Reservation_data.map((item) => (
                                      <option
                                        className="Budget_Option"
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.time_data}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <i
                                    className="fa fa-caret-down profile_time_dropdown_icons "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </Col>

                              <Col
                                md={4}
                                className="profile_dropdown_session_title"
                              >
                                <p>prior no session start time</p>
                              </Col>
                              <Col
                                md={2}
                                className="profile_dropdown_session_title"
                              >
                                <Button
                                  className="submit_button_provider"
                                  type="submit"
                                  variant="danger"
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>

              <div>
                <Row>
                  <Col md={12}>
                    <div className="profile_logo_card_wrapper">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="profile_logo_padding_cards">
                            <div className="profile_logo_reservation">
                              <h6>Closed Dates</h6>

                              <p>
                                List the dates your bussiness is closed for
                                public
                              </p>
                            </div>

                            <Row>
                              <Col md={12}>
                                <div className="profile_appointment_time_wrapper">
                                  <DatePicker
                                    name="appointment_time"
                                    selected={this.state.close_date}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                      this.Onchange_Date_Function(date);
                                    }}
                                  />
                                  <p
                                    onClick={this.add_date_time_function}
                                    className="profile_appointment_add_button"
                                  >
                                    Add Dates
                                  </p>
                                </div>

                                <div>
                                  <div>
                                    {date_array_list.length !== 0 && (
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>Sno</th>
                                            <th>Time</th>
                                            <th>Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {date_array_list.map(
                                            (array_list_item, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {Moment(
                                                    array_list_item.close_date
                                                  ).format("MM-DD-YYYY")}
                                                </td>
                                                <td>
                                                  <i
                                                    onClick={() => {
                                                      this.remove_date_time_function(
                                                        index
                                                      );
                                                    }}
                                                    class="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <DropzoneDialog
          open={this.state.multiple_upload_modal}
          onSave={this.handleSave.bind(this)}
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          filesLimit={20}
          maxFileSize={5000000}
          onClose={this.multiple_upload_hide}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studio_list: state.ProviderReducer.studio_list,
    customer_object_data: state.ProviderReducer.customer_object_data,
    studio_object_data: state.ProviderReducer.studio_object_data,
    images_address_list: state.ProviderReducer.images_address_list,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
    category_list_data: state.ProviderReducer.category_list_data,
  };
};

export default connect(mapStateToProps, null)(Profile);
