import React, { Component } from "react";
import Header from "../../Dabblefit/Common/Header/Header";
import Footer from "../../Dabblefit/Common/Footer/Footer";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import "./ProviderSignIn.css";
import * as ProviderActions from "../../redux/actions/Provider";
import { connect } from "react-redux";
import SuccessModal from "../../constants/SuccessModal/SuccessModal";
import Config from "../../../Config";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';


class ProviderSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      client_ip: "",
      errors: {},
      type: "password",
      modal_show: false,
      forget_modal: false,

      mobile_number: "",
      email_id: "",
      errors: {},
      button_disabled: true,
      loading_screen:true,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    setTimeout(() => {
      let button_disabled = Config.button_disable([
        this.state.password,
        this.state.username,
      ]);
      this.setState({ button_disabled: button_disabled });
    }, 1);
  };

  password_toogle_eye = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  add_login_function = (e) => {
    e.preventDefault();
    const { username, password, client_ip } = this.state;
    this.props.dispatch(
      ProviderActions.SignInAction(username, password, client_ip)
    );
  };
  componentDidUpdate = () => {
    if (this.props.success_message === "SIGN_IN_SUCCESS") {
      this.props.dispatch(ProviderActions.empty_message());
      this.props.dispatch(ProviderActions.GetStudioData());
     
     
    

      this.props.history.push("/Provider");
    } else if (this.props.success_message === "SIGN_IN_ERROR") {
      
      toast.error(this.props.error_message);
      this.props.dispatch(ProviderActions.empty_message());

    } else if (this.props.success_message === "SIGN_IN_CATCH_ERROR") {
      toast.error(this.props.error_message);
      this.props.dispatch(ProviderActions.empty_message());
    } else if (this.props.success_message === "RESET_SUCCESS") {
      toast.success(
        "Reset Password link sent to your e-mail address. please follow the instructions.."
      );
      this.setState({ forget_modal: false, email_id: "", mobile_number: "" });
      this.props.dispatch(ProviderActions.empty_message());
    } else if (this.props.success_message === "RESET_ERROR") {
      toast.error(this.props.error_message);
      this.setState({ forget_modal: false, email_id: "", mobile_number: "" });

      this.props.dispatch(ProviderActions.empty_message());
    } else if (this.props.success_message === "RESET_CATCH_ERROR") {
      toast.error(this.props.error_message);
      this.setState({ forget_modal: false, email_id: "", mobile_number: "" });
      this.props.dispatch(ProviderActions.empty_message());
    }
  };

  forget_handle_close = () => {
    this.setState({ forget_modal: false, email_id: "", mobile_number: "" });
  };
  forget_modalshow = () => {
    this.setState({ forget_modal: true });
  };

  onHandleTelephoneChange = (e) => {
    let mobile_number = e.target.value;
    mobile_number = Config.mobile_validation(mobile_number);
    this.setState({ [e.target.name]: mobile_number });

    setTimeout(() => {
      let button_disabled = Config.button_disable([
        this.state.email_id,
        this.state.mobile_number,
      ]);
      this.setState({ button_disabled: button_disabled });
    }, 1);
   
  };

  handletoemail = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.mail_validation(event.target.value);
    this.setState({ errors: errors });

    setTimeout(() => {
      let button_disabled = Config.button_disable([
        this.state.email_id,
        this.state.mobile_number,
      ]);
      this.setState({ button_disabled: button_disabled });
    }, 1);
   
  };

  forget_handleSubmit = (e) => {
   
    e.preventDefault();

    const { email_id, mobile_number } = this.state;

    const form_Data1 = JSON.stringify({ email_id, mobile_number });
    this.props.dispatch(ProviderActions.PasswordResetAction(form_Data1));
  };

  render() {
    
    const { loading_screen,mobile_number } = this.state;
    // if(loading_screen===true)
    // {
    //  return(
    //  <div >
    //     <LoadingOverlay
    
    //   active="true"
    //   spinner
    //   text='Loading Your Data'
    //   >
    // </LoadingOverlay>
    //  </div>
    //  )
    // }
    
    return (
      <div>
       
        <Header />
        <SuccessModal
          subtitle="Your process is ongoing once verified we will contact"
          title="Registration Is Done"
          modal_show={this.state.modal_show}
          handleClose={this.handleClose}
          modal_backdrop="static"
          modal_keyboard={false}
          location_navigate="/"
        />

        <Form onSubmit={this.add_login_function}>
          <section
            className="provider_sign_in_wrapper"
            id="provider_sign_in_wrapper"
          >
            <Container>
              <Row>
                <Col md={12} className="text-center">
                  <div className="get_int_title">
                    <h6>Sign In</h6>
                  </div>
                  <div>
                    <Row>
                      <Col
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 8, offset: 2 }}
                      >
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="User Name"
                              name="username"
                              onChange={this.handleChange}
                              required
                            />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-user" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>

                      <Col
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 8, offset: 2 }}
                      >
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type={this.state.type}
                              placeholder="Password"
                              name="password"
                              onChange={this.handleChange}
                              required
                            />
                            <span
                              className="password__show"
                              onClick={this.password_toogle_eye}
                            >
                              <i
                                className={
                                  this.state.type === "text"
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                                aria-hidden="true"
                              ></i>
                            </span>
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-lock" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>

                      <Col
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 8, offset: 2 }}
                      >
                        <div className="contact_dashboard_from_password">
                          <span onClick={this.forget_modalshow}>
                            Forget Password
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div className="provider_signin_buttton_wrapper">
                      <Button
                        disabled={this.state.button_disabled}
                        className="submit_button_provider"
                        type="submit"
                        variant="danger"
                      >
                        Sign In
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Form>

        <Modal
          centered
          show={this.state.forget_modal}
          onHide={this.forget_handle_close}
        >
          <Form onSubmit={this.forget_handleSubmit}>
            <Modal.Header>
              <Modal.Title className="success_modal_title">
                Reset your password
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="get_in_form_wrapper">
                <p className="modal_create_para"></p>

                <div className="sign_form_wrapper">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      name="mobile_number"
                      placeholder="Mobile Number"
                      value={mobile_number}
                      onChange={this.onHandleTelephoneChange}
                      maxLength={10}
                      minLength={10}
                      required
                    />
                    <div className="sign_form_icons">
                      <i className="fa fa-mobile" aria-hidden="true"></i>
                    </div>
                  </Form.Group>
                </div>

                <div className="sign_form_wrapper">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Mail Id"
                      name="email_id"
                      value={this.state.email_id}
                      onChange={this.handletoemail}
                    />
                    <div className="sign_form_icons">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </Form.Group>
                </div>
                <div className="text-danger text-center">
                  {this.state.errors.email}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="get_in_register">
                <Button
                  disabled={this.state.button_disabled}
                  type="submit"
                  variant="danger"
                >
                  Reset
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gym_activity_data: state.ProviderReducer.gym_activity_data,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
  };
};

export default connect(mapStateToProps, null)(ProviderSignUp);
