import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

// Dabble fit
import Homepage from "./components/Dabblefit/Homepage/Homepage";
import Search from "./components/Dabblefit/Search/Search";
import OurFacilities from "./components/Dabblefit/OurFacilities/OurFacilities";
import OurTrainers from "./components/Dabblefit/OurTrainer/OurTrainers";
import Register from "./components/Dabblefit/Register/Register";
import OurBlogs from "./components/Dabblefit/OurBlogs/OurBlogs";
import Consumer from "./components/Dabblefit/Consumer/Consumer";

// Provider dasboard
import ProviderLayout from './components/ProviderDashboard/ProviderLayout/ProviderLayout';
import UserProfile from './components/ProviderDashboard/UserProfile/UserProfile';
import ProviderSignUp from './components/ProviderDashboard/ProviderSignUp/ProviderSignUp';
import ProviderSignIn from './components/ProviderDashboard/ProviderSignIn/ProviderSignIn';
import ChangePassword from './components/ProviderDashboard/ChangePassword/ChangePassword';
import MailVerification from './components/ProviderDashboard/MailVerification/MailVerification';






// Vimkes Tech

import Home from './components/vimkes/Homepage/Homepage';
import Contact from './components/vimkes/Contact/Contact';
import About from './components/vimkes/About/About';
import SoftwareDevelopment from './components/vimkes/SoftwareDevelopment/SoftwareDevelopment';

import BlogList from './components/vimkes/BlogList/BlogList';
import BlogDescription from './components/vimkes/BlogDescription/BlogDescription';









// Vimkes Tech

function App() {
  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/" name="SiteHome Page" render={props => <Home {...props} />} />

        <Route exact path="/Contact" name="Contact Page" render={props => <Contact {...props} />} />
        <Route exact path="/About" name="Contact Page" render={props => <About {...props} />} />
        <Route exact path="/SoftwareDevelopment" name="SoftwareDevelopment Page" render={props => <SoftwareDevelopment {...props} />} />
        <Route exact path="/BlogList" name="BlogList Page" render={props => <BlogList {...props} />} />
        <Route exact path="/BlogDescription" name="BlogDescription Page" render={props => <BlogDescription {...props} />} />

     
        
        <Route exact path="/Search" name="Search Page" render={props => <Search {...props} />} />
        <Route exact path="/OurFacilities" name="OurFacilities Page" render={props => <OurFacilities {...props} />} />
        <Route exact path="/OurTrainers" name="OurFacilities Page" render={props => <OurTrainers {...props} />} />
        <Route exact path="/Register" name="Register Page" render={props => <Register {...props} />} />
        <Route exact path="/OurBlogs" name="OurBlogs Page" render={props => <OurBlogs {...props} />} />
        <Route exact path="/Consumer" name="Consumer Page" render={props => <Consumer {...props} />} />
        <Route exact path="/Provider" name="ProviderLayout Page" render={props => <ProviderLayout {...props} />} />
        <Route exact path="/UserProfile" name="UserProfile Page" render={props => <UserProfile {...props} />} />
        <Route exact path="/ProviderSignUp" name="ProviderSignUp Page" render={props => <ProviderSignUp {...props} />} />
        <Route exact path="/ProviderSignIn" name="ProviderSignIn Page" render={props => <ProviderSignIn {...props} />} />
        <Route exact path="/ChangePassword/:hps" name="ChangePassword Page" render={props => <ChangePassword {...props} />} />
        <Route exact path="/MailVerification" name="MailVerification Page" render={props => <MailVerification {...props} />} />




 


      </Switch>
    </BrowserRouter>
  );
}

export default App;
