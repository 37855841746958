



// Dabble fit images 



const dabble_logo=require('../../assets/dabblefit/logo/logo.png');


const header_after=require('../../assets/dabblefit/logo/headerafter.png');

const dabble_title=require('../../assets/dabblefit/banner/dabbletitle.png');

const mobile_service=require('../../assets/dabblefit/mobile/service_1.png');
const gallery_event=require('../../assets/dabblefit/gallery/events.png');
const gallery1=require('../../assets/dabblefit/gallery/gallery1.png');
const gallery2=require('../../assets/dabblefit/gallery/gallery2.png');
const gallery3=require('../../assets/dabblefit/gallery/gallery3.png');
const gallery4=require('../../assets/dabblefit/gallery/gallery4.png');
const gallery5=require('../../assets/dabblefit/gallery/gallery5.png');
const gallery6=require('../../assets/dabblefit/gallery/gallery6.png');

const footer_logo=require('../../assets/dabblefit/footer/footer_logo.png');


const brand_image1=require('../../assets/dabblefit/brand/adidas.png');
const brand_image2=require('../../assets/dabblefit/brand/nike.png');
const brand_image3=require('../../assets/dabblefit/brand/puma.png');

const brand_image4=require('../../assets/dabblefit/brand/audi.jpg');
const brand_image5=require('../../assets/dabblefit/brand/puma.jpg');
const brand_image6=require('../../assets/dabblefit/brand/sports.jpg');



const search_background=require('../../assets/dabblefit/search/bg.png');
const workout=require('../../assets/dabblefit/workout/bg.jpg');
const workout_bg=require('../../assets/dabblefit/work/bg.png');



const slam_logo=require('../../assets/dabblefit/ourfacilites/logo/logo.png');
 
const cctv=require('../../assets/dabblefit/ourfacilites/icons/cctv.png');
const locker=require('../../assets/dabblefit/ourfacilites/icons/locker.png');
const parking=require('../../assets/dabblefit/ourfacilites/icons/parking.png');
const shower=require('../../assets/dabblefit/ourfacilites/icons/shower.png');
const workout_banner=require('../../assets/dabblefit/ourfacilites/banner/workout.jpg');

const register_offer_1=require('../../assets/dabblefit/register/service/service-1.png');
const register_offer_2=require('../../assets/dabblefit/register/service/service-2.png');
const register_offer_3=require('../../assets/dabblefit/register/service/service-3.png');

const register_service_bg=require('../../assets/dabblefit/register/servicebg/servicebg.png');
const register_how_bg=require('../../assets/dabblefit/register/servicebg/howitworks.png');

// Blog section
const blog_image1=require('../../assets/dabblefit/blog/blog1.png');
const blog_image2=require('../../assets/dabblefit/blog/blog2.png');
const blog_image3=require('../../assets/dabblefit/blog/blog3.png');
const blog_image4=require('../../assets/dabblefit/blog/blog4.png');
// Blog section

// Consumer section
const consumer_icon1=require('../../assets/dabblefit/consumer/icons/image-1.png');
const consumer_icon2=require('../../assets/dabblefit/consumer/icons/image-2.png');
const consumer_icon3=require('../../assets/dabblefit/consumer/icons/image-3.png');
// Consumer section

// Provider Dashboard


const provider_dashboard_phone=require('../../assets/providerdashboard/contact/icons/phone.png');
const provider_dashboard_mail=require('../../assets/providerdashboard/contact/icons/mail.png');

const user_login_icon=require('../../assets/providerdashboard/user/user.png');












// Provider Dashboard


// End Dabble fit images


export default {




    dabble_logo,
    gallery_event,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    header_after,
    mobile_service,
    footer_logo,
  
    dabble_title,
    brand_image1,
    brand_image2,
    brand_image3,
    brand_image4,
    brand_image5,
    brand_image6,
    search_background,
    workout,
    slam_logo,
    cctv,
    parking,
    shower,
    locker,
    workout_banner,

    register_offer_1,
    register_offer_2,
    register_offer_3,

    register_service_bg,
    register_how_bg,
    blog_image1,
    blog_image2,
    blog_image3,
    blog_image4,


    consumer_icon1,
    consumer_icon2,
    consumer_icon3,

    provider_dashboard_phone,
    provider_dashboard_mail,
    user_login_icon,
    workout_bg,
 




  };
  