import React from 'react';
import './GetstartedButton.css';



const GetStartedButton = (props) => {
    return (

        <div className="get-started-wrapper">
            <p>{props.title}</p>
        </div>
       
    );
}

export default GetStartedButton;