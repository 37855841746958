import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./OurTrainers.css";
import { Container, Row, Col } from "react-bootstrap";
import Date_Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Date_data } from "../../constants/AccountData";
import "react-rangeslider/lib/index.css";
import images from "../../constants/images";
import StarRatingComponent from "react-star-rating-component";
export default class OurTrainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      r: 0,
      rating: 1,
    };
  }
  render() {
    const { rating } = this.state;
    const Date_slider = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 10,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };

    const Addional_Rooms_Data = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };
    return (
      <section className="our_trainers_section" id="our_trainers_section">
        <Header />

        <section
          className="our_title_wrap common_top_padding"
          id="our_title_wrap"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="our_title_container">
                  <h1>
                    OUR <span>TRAINERS</span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our_trainers_about" id="our_trainers_about">
          <Container>
            <Row>
              <Col md={5} xl={6}>
                <div className="our_trainers_wrap">
                  <img
                    src={images.slam_logo}
                    alt="dabblefit"
                    className="slam_logo"
                  />
                  <span className="background_overlay"></span>
                </div>

                <div></div>

                <div className="our_trainers_location">
                  <div className="our_trainers_inner">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <h6>RK salai</h6>
                    <h6>Adyar</h6>
                    <h6>Nungambakam</h6>
                  </div>
                </div>

                <div className="our_verified_container">
                  <div className="our_verified_round"></div>
                  <div className="our_verfied_star">
                    <p>VERIFIED</p>
                  </div>
                  <div className="our_verfied_star_icons">
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={rating}
                    />
                  </div>
                </div>

                <div className="our_area_expertise">
                  <h6>AREA OF EXPERTISE</h6>

                  <div className="our_area_expertise_inner">
                    <p>Strength Training</p>
                    <p>Muscle Gain</p>
                    <p>Weight Loss</p>
                    <p>Sports Perfomance Training</p>
                    <p>Injury Prevention</p>
                  </div>
                </div>

                <div className="our_area_about">
                  <h6>ABOUT LOREM IPSUM</h6>

                  <p>
                    lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
                <div className="d-none d-sm-block">
                <div className="our_about_icons_wrapper">
                  <div>
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <p>HOME</p>
                  </div>
                  <div>
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <p>AT STUDIO</p>
                  </div>
                  <div>
                    <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                    <p>VIRTUAL</p>
                  </div>
                </div>
                </div>

            
              </Col>

              <Col md={7} xl={6} className="our_facilities_slider our_facilites_slider_bottom">
                <Row>
                  <Col md={12}>
                    <Date_Slider {...Addional_Rooms_Data}>
                      {(Date_data || []).map((x, index) => {
                        return (
                          <div className="our_facilites_image_wrapper">
                            <img
                              src={images.workout_banner}
                              className="workout_banner"
                              alt="dabblefit"
                            />
                          </div>
                        );
                      })}
                    </Date_Slider>
                  </Col>
                  <Col md={12} className="our_session_wrapper">
                    <div className="our_session_wrapper_inner">
                    <Row>
                      <Col md={12}>
                        <div className="training_subscription_wrapper">
                          <p>1 -on- 1 Training Subscriptions</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <Row>
                          <Col md={4} xs={4} className="our_session_wrapper_parent">
                            <div className="our_session_wrapper_after">
                              <h6>4 Sessions</h6>
                              <h5>&#8377; 400</h5>
                              <p>Per Session</p>
                            </div>
                          </Col>
                          <Col md={4} xs={4} className="our_session_wrapper_parent">
                            <div className="our_session_wrapper_after">
                              <h6>12 Sessions</h6>
                              <h5>&#8377; 750</h5>
                              <p>Per Session</p>
                            </div>
                          </Col>
                          <Col md={4} xs={4}>
                            <div className="">
                              <h6>24 Sessions</h6>
                              <h5>&#8377; 1100</h5>
                              <p>Per Session</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={12}>
                  <div className="d-block  d-sm-none">
                <div className="our_about_icons_wrapper">
                  <div>
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <p>HOME</p>
                  </div>
                  <div>
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <p>AT STUDIO</p>
                  </div>
                  <div>
                    <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                    <p>VIRTUAL</p>
                  </div>
                </div>
                </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="our_certification_section "
          id="our_certification_section"
        >
          <Container>
            <Row>
              <Col md={6}>
                <div className="our_certification_wrapper">
                  <h6>certification</h6>
                  <div className="our_area_expertise_inner">
                    <p>Strength Training</p>
                    <p>Muscle Gain</p>
                  </div>
                </div>

                <div className="our_certification_about">
                  <h6>About Lorem Ipsum is simply</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </p>
                </div>

                <div className="our_certification_about">
                  <h6>About Lorem Ipsum is simply</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scramb
                  </p>
                </div>

                <div className="our_certification_about">
                  <h6>About Lorem Ipsum is simply</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scramb
                  </p>
                </div>

                <Row>
                  <Col md={12}>
                    <div className="gallery_view">
                      <p>View More</p>
                      <div className="view_triangle"></div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={6} className="our_trainer_date">
                <Row>
                  <Col md={12}>
                    <Col md={12}>
                      <div className="training_session_wrapper">
                        <p>Book a Session Now!</p>
                      </div>
                    </Col>
                    <Row>
                      <Col
                        md={12}
                        xs={12}
                        className="our_trainer_wrapper_media"
                      >
                        <Date_Slider {...Date_slider}>
                          {(Date_data || []).map((x, index) => {
                            return (
                              <div className="date_wrapper_list">
                                <div className="date_wraper_inner_list">
                                  <h6>{x.date_number}</h6>
                                  <h6>{x.date_month}</h6>
                                </div>
                              </div>
                            );
                          })}
                        </Date_Slider>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="our_trainner_time">
                  <Row>
                    <Col md={12} xs={12}>
                      <div className="our_tainer_timer">
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                        <div>
                          <p>5.30 PM</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="our_tainer_timer_book">
                        <p>Book</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our_trainer_date" id="our_trainer_date" hidden>
          <Container>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <Row>
                  <Col md={2} className="our_trainer_title">
                    <div>
                      <h6>Date</h6>
                    </div>
                  </Col>
                  <Col md={9} xs={12} className="our_trainer_wrapper_media">
                    <Date_Slider {...Date_slider}>
                      {(Date_data || []).map((x, index) => {
                        return (
                          <div className="date_wrapper_list">
                            <div className="date_wraper_inner_list">
                              <h6>{x.date_number}</h6>
                              <h6>{x.date_month}</h6>
                            </div>
                          </div>
                        );
                      })}
                    </Date_Slider>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>

            <div className="our_trainner_time">
              <Row>
                <Col md={2}></Col>
                <Col md={8} xs={12}>
                  <div className="our_tainer_timer">
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                    <div>
                      <p>5.30 PM</p>
                    </div>
                  </div>
                </Col>
                <Col md={2}></Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md={12} className="text-center">
                  <div className="our_tainer_timer_book">
                    <p>Book</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="disclaimer_section" id="disclaimer_section">
          <Container>
            <Row>
              <Col md={12} className="disclaimer_card">
                <Row>
                  <Col md={12}>
                    <div className="disclaimer_title">
                      <h6>HOW IT WORKS</h6>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <div className="disclaimer_description">
                      <ul>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                          1500s,Lorem Ipsum has been the industry's standard
                          dummy text ever since the 1500s,Lorem Ipsum has been
                          the industry's .
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </section>
    );
  }
}
