import React from 'react';
import './DescriptionList.css'

const DescriptionList = (props) => {
    return (
        <div>
              
                   
                     <div  key={props.id} className="list-details-mainwrap">
                      <img src={props.image_url} className="img-fluid" alt="vimkes" />
                     <div className="list-details-description">
                       <h3>{props.title}</h3>
                       <p>{props.description}</p>
                     </div>
                    </div>
        </div>
    );
};

export default DescriptionList;