import * as redux from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import ProviderReducer from '../reducers/Provider'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const rootReducer = redux.combineReducers({
  ProviderReducer:ProviderReducer,
   });
    
  const persistConfig = {
    key: 'root',
    storage,
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
  
export const store = redux.createStore(persistedReducer,composeWithDevTools(redux.applyMiddleware(thunk)));

export const persistedStore =persistStore(store);
