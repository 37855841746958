import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./Consumer.css";
import { Container, Row, Col, Form } from "react-bootstrap";

import images from '../../constants/images';
export default class Consumer extends Component {
  render() {
    return (
      <div className="consumer_section" id="consumer_section">
        <Header />
        <section
          className="consumer_banner_section"
          id="consumer_banner_section"
        >
          <div className="consumer_banner_section_overlay"></div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="consumer_banner_content">
                  <h1>
                    YOUR TICKET INTO A WORLD OF <span>FITNESS</span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="consumer_service_section"
          id="consumer_service_section"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="consumer_service_title">
                  <h6>
                    GET ACCESS TO A DIVERSE RANGE OF FITNESS OPTIONS AND
                    FACILITIES ALL WITH ONE <span>MEMBERSHIP</span>
                  </h6>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="consumer_cards_section" id="consumer_cards_section">
          <Container>
            <Row>
              <Col md={12}>
                <div className="consumer_card_wrapper">
                  <Row>
                    <Col md={4}>
                      <div className="consumer_card_container">
                        <div className="consumer_card_images">
                          <img
                            src={images.consumer_icon1}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="consumer_card_title">
                          <h6>ANY <br/> WORKOUT </h6>
                        </div>

                        <div className="consumer_card_description">
                          <p>
                          Choose from a diverse list of fitness options available in your city
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="consumer_card_container">
                        <div className="consumer_card_images">
                          <img
                            src={images.consumer_icon2}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="consumer_card_title">
                          <h6>ANY TIME AND LOCATION</h6>
                        </div>

                        <div className="consumer_card_description">
                          <p>
                          Workout at any of our partnered facilties / studios. At a time and place of your choice
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="consumer_card_container consumer_card_container1">
                        <div className="consumer_card_images">
                          <img
                            src={images.consumer_icon3}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="consumer_card_title">
                          <h6>INDEPENDENT SPECIALISTS</h6>
                        </div>

                        <div className="consumer_card_description">
                          <p>
                          Book one-on-one / group sessions with Independent trainers who specialize in various fields
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="mobile_section consumer_how_section"
          id="mobile_section"
        >
          <Container>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_content">
                  <h1>
                    how it <span>works</span>
                  </h1>
                  <div className="mobile_description"></div>
                </div>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_service">
                  <img
                    src={images.mobile_service}
                    alt="Dabblefit"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_button_container">
                  <div className="banner_button">
                    <a href="/">Get Started</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="mobile_right_container">
            <img
              src={images.mobile_right}
              alt="Dabblefit"
              className="img-fluid"
            />
          </div>
        </section>

        <section
          className="consumer_signup_section"
          id="consumer_signup_section"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="consumer_signup_title">
                 
                  <h1>
                 Sign <span>Up</span>
                  </h1>

                  <p>
                    Be amongst the first few to receive special offers and
                    notifications regarding our launch
                  </p>
                </div>
              </Col>

              <Col md={12} lg={{ span: 8, offset: 2 }}>
                <div className="get_in_form_wrapper">
                  <Form>
                    <div className="sign_form_wrapper">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label></Form.Label>
                        <Form.Control type="text" placeholder="Name" />
                        <div className="sign_form_icons">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="sign_form_wrapper">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label></Form.Label>
                        <Form.Control type="text" placeholder="Email Id" />
                        <div className="sign_form_icons">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="sign_form_wrapper">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label></Form.Label>
                        <Form.Control type="text" placeholder="Mobile" />
                        <div className="sign_form_icons">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="get_in_register get_in_register_top">
                      <p>Submit</p>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        




        <Footer />
      </div>
    );
  }
}
