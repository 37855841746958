import React from 'react';
import './HeaderImageSection.css';
import {Link } from 'react-router-dom';

const HeaderImageSection = (props) => {
    return (
        <div>
             <section className="common-header">
             <div className={`common-header-img ${props.commonheaderinner}`}>
            <div className="common-overlay"></div>
            <div>
              <p className="common-title">{props.title} <span className="common-home-link"><Link to="/">Home</Link>  / {props.secondtitle} </span></p>
              
            </div>
          </div>
        </section>
        </div>
    );
};

export default HeaderImageSection;