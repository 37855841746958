import React, { Component } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import "./Account.css";
import { connect } from "react-redux";
import * as ProviderActions from "../../redux/actions/Provider";
import PasswordStrengthBar from "react-password-strength-bar";
import Config from "../../../Config";
import { toast } from "react-toastify";

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal_show: false,
      imagePreviewUrl: "https://wallpapercave.com/wp/wp2356164.jpg",
      image_address: "https://wallpapercave.com/wp/wp2356164.jpg",

      new_password: "",
      confirm_password: "",
      type: "password",
      type1: "password",
      retype_password_error: "",
      password_strength: "",
      change_button_disable: true,
      change_password_score: "",

      contact_name: this.props.studio_object_data.contact_name,
      contact_number: this.props.studio_object_data.contact_number,
      contact_email_id: this.props.studio_object_data.contact_email_id,
      location_id:this.props.studio_object_data.id,
    };
  }
  modale_show_function = () => {
    this.setState({contact_name: this.props.studio_object_data.contact_name,
      contact_number: this.props.studio_object_data.contact_number,
      contact_email_id: this.props.studio_object_data.contact_email_id,  location_id:this.props.studio_object_data.id })

    this.setState({ modal_show: true });
  };
  model_hide_function = () => {


    this.setState({ modal_show: false });


  };

  onFileChangeHandler = (e) => {
    e.preventDefault();
    console.log(e.target.files[0]);
    this.setState({ image_address: e.target.files[0] });
    let selectorFiles = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectorFiles);
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    const Authorization = localStorage.getItem("Authorization");
    const form_Data1 = JSON.stringify({ Authorization });
    const formData = new FormData();
    formData.append("image_address", selectorFiles);
    formData.append("json", form_Data1);

    this.props.dispatch(ProviderActions.ProfileImageChange(formData));
    
  };

  password_toogle_eye = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  password_toogle_eye1 = () =>
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));

  contactHandleChange = (e) => {
    const { name, value } = e.target;
    console.log({ [name]: value });
    this.setState({ [name]: value });
  };

  handleChangeNewPassword = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({
      confirm_password: "",
      retype_password_error: "",
      change_button_disable: true,
    });
  };

  handleChangeRetypePassword = (event) => {
    const { new_password, change_password_score } = this.state;
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.password_validation(new_password, event.target.value);
    this.setState({ retype_password_error: errors });
    if (new_password === event.target.value && change_password_score >= 4) {
      this.setState({ change_button_disable: false });
    } else {
      this.setState({ change_button_disable: true });
    }
  };

  passwordChangeScore = (score) => {
    this.setState({ change_password_score: score });
  };

  changeNewPassword = (e) => {
    e.preventDefault();
    const { new_password, confirm_password } = this.state;
    const Authorization = localStorage.getItem("Authorization");
    const form_Data1 = JSON.stringify({
      new_password,
      confirm_password,
      Authorization,
    });
    this.props.dispatch(ProviderActions.changeNewPasswordAction(form_Data1));
  };

  contactUpdatePoint = (e) => {

    e.preventDefault();
    const { contact_name, contact_number, contact_email_id,location_id } = this.state;
    const Authorization = localStorage.getItem("Authorization");
    const form_Data1 = JSON.stringify({
      contact_name,
      contact_number,
      contact_email_id,
      Authorization,
      location_id
    });
    this.props.dispatch(ProviderActions.contactUpdatePointAction(form_Data1));
  };

 

  componentDidUpdate = () => {
    if (this.props.success_message === "CHANGE_PASSWORD_SUCCESS") {
      this.setState({ new_password: "", confirm_password: "" });
      toast.success("Successfully updated ..");
      this.props.dispatch(ProviderActions.empty_message());
    } else if (this.props.success_message === "CHANGE_PASSWORD_ERROR") {
      toast.error("Can't Perform current action Now, Please Try again later..");
      this.setState({ new_password: "", confirm_password: "" });
      this.props.dispatch(ProviderActions.empty_message());
    } else if (this.props.success_message === "CONTACT_UPDATE_SUCCESS") {
      toast.success("Contact Update SucessFully");
      this.props.dispatch(ProviderActions.empty_message());

      let studio_object=this.props.studio_object_data;
      studio_object.contact_name=this.state.contact_name;
      studio_object.contact_number=this.state.contact_number;
      studio_object.contact_email_id=this.state.contact_email_id;
      this.props.dispatch(ProviderActions.get_studio_list_update(studio_object));
      console.log("contact updated");

       this.setState({ modal_show: false });

    } else if (this.props.success_message === "CONTACT_UPDATE_ERROR") {
      toast.error(this.props.error_message);
      this.props.dispatch(ProviderActions.empty_message());
    }
  };

  render() {
    const { new_password, retype_password_error } = this.state;
    return (
      <div>
        <Row >
          <Col md={12}>
            <Row>
              <Col md={8} lg={10} xl={12}>
                <div className="account_dasboard_wrapper">
                  <h6 className="account_dasboard_heading">Account</h6>

                  <Row>
                    <Col md={6} lg={6} className="account_dashboard_card dasoar_blue">
                      <Row>
                        <Col md={6}>
                          <div className="account_dashboard_inner">
                            <div className="account_dashboard_name_conent">
                              <h5>
                                Studio Name :{" "}
                                <span>
                                  {this.props.customer_object_data.company_name}
                                </span>
                              </h5>
                            </div>
                            <div className="account_dashboard_name_conent">
                              <h5>
                                Partner Since :{" "}
                                <span>
                                  {this.props.customer_object_data.update_date}
                                </span>
                              </h5>
                            </div>

                            <div className="account_dashboard_name_conent">
                              <h5>
                                Email ID :{" "}
                                <span>
                                  {this.props.customer_object_data.email_id}
                                </span>
                              </h5>
                            </div>
                            <div className="account_dashboard_name_conent">
                              <h5>
                                Studio Number :{" "}
                                <span>
                                  {
                                    this.props.customer_object_data
                                      .mobile_number1
                                  }
                                </span>
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div>
                            <div className="profile_logo_image_container">
                              <img
                                src={
                                  this.props.customer_object_data.image_address
                                }
                                className="img-fluid"
                                alt="dabblefit"
                              />
                              <h6>
                                Minimum Size Must be{" "}
                                <span> 1242 * 828 pixels</span>
                              </h6>
                            </div>

                            <Button
                              type="button"
                              size="md"
                              color="success"
                              style={{ height: "40px" }}
                            >
                              <label htmlFor="image_address">
                                <i className="fa fa-upload"></i> Upload
                              </label>
                              <input
                                hidden
                                id="image_address"
                                name="image_address"
                                type="file"
                                accept="image/*"
                                onChange={this.onFileChangeHandler}
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Form onSubmit={this.changeNewPassword}>
                        <Row>
                          <Col md={6}>
                            <div className="contact_dashboard_form_wrapper">
                              <Form.Group controlId="formBasicPassword">
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type={this.state.type}
                                  placeholder="Type New Password"
                                  name="new_password"
                                  onChange={this.handleChangeNewPassword}
                                  value={this.state.new_password}
                                />
                                <span
                                  className="password__show"
                                  onClick={this.password_toogle_eye}
                                >
                                  <i
                                    className={
                                      this.state.type === "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <div className="contact_dashboard_form_icons provider_icons_color">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </Form.Group>
                              {new_password && (
                                <PasswordStrengthBar
                                  onChangeScore={(score) => {
                                    this.passwordChangeScore(score);
                                  }}
                                  password={new_password}
                                />
                              )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="contact_dashboard_form_wrapper">
                              <Form.Group controlId="formBasicPassword">
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type={this.state.type1}
                                  placeholder="Type It Again"
                                  name="confirm_password"
                                  onChange={this.handleChangeRetypePassword}
                                  value={this.state.confirm_password}
                                />
                                <span
                                  className="password__show"
                                  onClick={this.password_toogle_eye1}
                                >
                                  <i
                                    className={
                                      this.state.type1 === "text"
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <div className="contact_dashboard_form_icons provider_icons_color">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </Form.Group>
                              <div className="text-danger">
                                {retype_password_error}
                              </div>
                            </div>
                          </Col>
                          <Col md={12} className="text-center">
                            <div className="provider_signin_buttton_wrapper">
                              <Button
                                type="submit"
                                variant="danger"
                                disabled={this.state.change_button_disable}
                              >
                                Change Password
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} lg={6} className="account_dashboard_card">
                      <div className="account_dashboard_edit_wrapper">
                        <div>
                          <h5>Point of Contact</h5>
                        </div>

                        <div onClick={this.modale_show_function}>
                          <p>Edit</p>
                        </div>
                      </div>
                      <div className="">
                        <div className="account_dashboard_name_conent">
                          {/* <h5>Name : <span>{this.props.studio_object_data.contact_name}</span></h5> */}
                          <h5>
                            Name :{" "}
                            <span>
                              {this.props.studio_object_data.contact_name}
                            </span>
                          </h5>
                        </div>
                        <div className="account_dashboard_name_conent">
                          <h5>
                            Phone :{" "}
                            <span>
                              {this.props.studio_object_data.contact_number}
                            </span>
                          </h5>
                        </div>

                        <div className="account_dashboard_name_conent">
                          <h5>
                            Email ID :{" "}
                            <span>
                              {this.props.studio_object_data.contact_email_id}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} lg={6} className="account_dashboard_card">
                      <div className="account_dashboard_Terms_Dashboard">
                        <div>
                          <p>Terms & Condition</p>
                        </div>
                        <div className="account_dashboard_button">
                          <p>View Terms & Condition</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} lg={6}>
                      <div className="Logout_button">
                        <p>Logout</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
       
          <Modal
            centered
            show={this.state.modal_show}
            onHide={this.model_hide_function}
          >
             <Form onSubmit={this.contactUpdatePoint}>
            <Modal.Header>
              <Modal.Title>Point of Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
              <div className="get_in_form_wrapper">
                <div className="sign_form_wrapper">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="contact_name"
                      onChange={this.contactHandleChange}
                      value={this.state.contact_name}
                      required
                    />
                    <div className="sign_form_icons">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                  </Form.Group>
                </div>

                <div className="sign_form_wrapper">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="number"
                      name="contact_number"
                      onChange={this.contactHandleChange}
                      placeholder="Phone Number"
                      value={this.state.contact_number}
                      required
                    />
                    <div className="sign_form_icons">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                  </Form.Group>
                </div>

                <div className="sign_form_wrapper">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="email"
                      name="contact_email_id"
                      onChange={this.contactHandleChange}
                      placeholder="Email"
                      value={this.state.contact_email_id}
                      required
                    />
                    <div className="sign_form_icons">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </Form.Group>
                </div>
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <div className="">
                <Button type="submit" variant="danger">
                  Update
                </Button>
              </div>
            </Modal.Footer>
            </Form>
          </Modal>
         
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studio_list: state.ProviderReducer.studio_list,
    customer_object_data: state.ProviderReducer.customer_object_data,
    studio_object_data: state.ProviderReducer.studio_object_data,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
  };
};

export default connect(mapStateToProps, null)(Account);
