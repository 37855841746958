import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./Search.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import Date_Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Date_data,Addional_Rooms_Data,} from '../../constants/AccountData';
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import images from "../../constants/images";


export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
         r:0,
        
        };
      }
  render() {
    const { r} = this.state;
    const Date_slider = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
      ],
    };
    return (
      <div className="search_section">
        <Header />

        <section
          className="search_our_activity common_top_padding"
          id="search_our_activity"
        >
          <Container className="search_before">
            <Row>
              <Col md={12}>
                <div className="our_activity_title">
                  <h1>
                    OUR <span>ACTIVITIES</span>
                  </h1>
                </div>
              </Col>
            </Row>
            <Row className="search_section_wrapper">
              <Col md={3} className="first_section_mobile"   >
            <div>
            <Form.Group
                  className="search_dropdown_wrap"
                  controlId="exampleForm.ControlSelect1"
                >
                  <Form.Control
                    className="search_section_dropdown input-lg "
                    as="select"
                  >
                    <option>LOCATION</option>
                    <option>ANNA NAGAR</option>
                    <option>NUNGABAKKAM</option>
                    <option>T NAGAR</option>
                  </Form.Control>
                  <i
                    class="fa fa-caret-down "
                    aria-hidden="true"
                  ></i>
                </Form.Group>
            </div>
              </Col>
              <Col md={6}  className="second_section_mobile"  >
                <div className="search_section_container" >
                  <div>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        className="search_section_text"
                        type="text"
                        placeholder="Search Here"
                      />
                    </Form.Group>
                  </div>

                  <div className="search_section_button">
                    <p>Search</p>
                  </div>
                  <div></div>
                </div>
              </Col>

              <Col md={3} className="third_section_mobile" >
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    className="search_section_dropdown input-lg"
                    as="select">
                    <option>FITNESS FORM</option>
                    <option>Gyms</option>
                    <option>Zumba</option>
                    <option>Gyms</option>
                    <option>Yoga</option>
                    <option>Gyms</option>
                    <option>Swimming</option>
                    <option>MMA And Kickboxing</option>
                    <option>Pilates</option>
                    <option>Dance</option>

                  
                  </Form.Control>
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </Form.Group>
              </Col>
            </Row>
             

             <div className="our_activities_date_wrapper">
            <Row>
            <Col md={{span: 8, offset: 2}} xs={12}>
                  <Date_Slider {...Date_slider}>
                   {(Date_data || []).map((x, index) => {
                                      return (
                                        <div className="date_wrapper_list">
                                   <div>
                                       <h6>{x.date_number}</h6>
                                       <h6>{x.date_month}</h6>
                                   </div>
                                               </div>);})}
                   </Date_Slider>
                  </Col>
            </Row>
            </div>


            {/* <Row className="search_section_wrapper">
         
              <Col md={12} className="search_date_mobile">
                <Row className="search_section_inner"> */}
                  {/* <Col md={3}>
                <div className="search_section_hightlight">
                  <div className="search_section_hightlight_first">
                 <h6> <span><i class="fa fa-user" aria-hidden="true"></i></span>&nbsp;Trainer</h6>

                  </div>
               <div className="search_section_hightlight_second">
               <h6> <span><i class="fa fa-home" aria-hidden="true"></i></span>&nbsp;Studios</h6>
               </div>

                  <div>

                  </div>
                </div>
                  </Col> */}
               
                  {/* <Col md={2}>
                    <div>
                      
                        <Form.Control
                          className="search_section_dropdown input-lg"
                          as="select"
                        >
                          <option>TIMING</option>
                          <option>Within 4 Hours</option>
                           <option>Later Today</option>
                        </Form.Control>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
              
                    </div>
                  </Col> */}
                {/* </Row>
              </Col>
            
            </Row> */}


            <Row className="align-items-center">
            <Col md={2}>
  
               </Col>
               <Col md={1}>
           <h6 className="text-white">COST</h6>
               </Col>
            <Col md={6} className="common_margin_bottom">
                    <div className="common_margin_top">
                      <div>
                        <Form>
                          <Form.Group controlId="formBasicRange ">
                            <Slider
                              defaultValue={0}
                              class="slider2"
                              id="slider2"
                              value={r}
                              orientation="horizontal"
                              onChange={this.handleChange_slider2}
                              max={20}
                              tooltip={false}
                            />
                             <div className="steps">
                              <span class="tick">
                                |<br />
                                <span class="marker">300</span>
                              </span>
                              <span class="tick tick1">
                                |<br />
                                <span class="marker">400</span>
                              </span>
                              <span class="tick tick2">
                                |<br />
                                <span class="marker">500</span>
                              </span>
                              <span class="tick tick3">
                                |<br />
                                <span class="marker">600</span>
                              </span>
                              <span class="tick tick4">
                                |<br />
                                <span class="marker">700</span>
                              </span>
                              <span class="tick tick5">
                                |<br />
                                <span class="marker">800</span>
                              </span>
                              <span class="tick tick6">
                                |<br />
                                <span class="marker">900</span>
                              </span>
                              <span class="tick tick7">
                                |<br />
                                <span class="marker">1000</span>
                              </span>
                             
                             
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                   
                   </Col>
            </Row>
          </Container>
        </section>


        <section className="search_card_list common_top_padding" id="search_card_list">
            <Container>
               
                  
                <Row>
                {(Addional_Rooms_Data || []).map((x, index) => {
                                      return (
                                        <Col md={6} className="search_card_wrapper">
                                        <div className="search_card_main">
                                            <div className="search_card_image_container">
                                           <img src={images.workout} alt="dabblefit"/>
                                            </div>
                                            <div className="search_card_content_wrap">
                                           <div className="search_card_content">
                                           <i class="fa fa-map-marker" aria-hidden="true"></i>
                                            <div >
                                                <h6>Slam</h6>
                                                <p>Rk Salai</p>
                                            </div>
                                           </div>
                                           
                                           <div className="search_card_activities">
                                            <h6>Activites</h6>
                                            <p>zumba, cross fit, spinning, gym</p>
                                           </div>
                                           <div className="search_card_time">
                                               <h6>100 <span>KCAL</span></h6>
                                               <span><i class="fa fa-clock-o" aria-hidden="true"></i> 60mins</span>
                                           </div>
                   
                                           <div className="search_card_time">
                                            <span>NEXT AVAILABLE TIME </span>
                                            <p>5.30 PM - 6.30 PM</p>
                                           </div>
                                           <div className="search_card_price">
                                           <div className="search_card_price_amount">
                                           <span>PRICE</span>
                                            <p>₹ 400</p>
                                           </div>
                                           <div className="search_card_book">
                                               <p>Book</p>
                                           </div>
                                           </div>
                                            </div>
                                        </div>
                                          
                                        
                                       </Col>);})}
                   
                  
                </Row>


              
               
            </Container>

        </section>
        <section>
          <Container>
          <Row>
                <Col md={12}>
                  <div className="gallery_view">
                    <p>View More</p>
                    <div className="view_triangle"></div>
                  </div>
                </Col>
              </Row>
          </Container>
        </section>


        <Footer />
      </div>
    );
  }
}
