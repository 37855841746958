import React, { Component } from "react";
import "./Schedule.css";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import Config from "../../../Config";
import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import * as ProviderActions from "../../redux/actions/Provider";
import { toast } from "react-toastify";

import { Repeat_data } from "../../constants/AccountData";
import * as Moment from "moment";

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_session_section: false,
      show_session_list: true,
      hide_end_date: false,
      session_type: "0",
      start_date: new Date(),
      end_date: new Date(),
      active_highlight_id: "",
      active_hightligh_status: "",
      repeat_on: "",
      repeat_on_list: Repeat_data,
      start_time: new Date(),
      end_time: new Date(),
      duration_value: "",

      studio_location_id: "",
      title: "",
      description: "",
      trainer_name: "",
      capacity: "",
      kilo_cals: "",
      activities: "",
      difficulty: "0",

      activity_ids: "",
      activity_names: "",
      selected_list1: [],
      btn_id: "add",
      id: "",
      session_schedule_array: [],
      time_array_list: [],
    };
  }

  componentDidMount = () => {
    // this.props.dispatch(ProviderActions.GetStudioData());
    let schedule_array = [];
    let schedule_list = this.props.schedule_list;
    let locations_id = this.props.studio_object_data.id;
    for (let i = 0; i < schedule_list.length; i++) {
      if (schedule_list[i].studio_location_id === locations_id) {
        var day_array = [];
        var repeat_on_data = schedule_list[i].repeat_on;

        if (repeat_on_data.includes(",")) {
          var res = repeat_on_data.split(",");
          console.log(res);
          for (var k = 0; k < res.length; k++) {
            for (var m = 0; m < Repeat_data.length; m++) {
              if (Repeat_data[m].key === res[k]) {
                day_array.push(Repeat_data[m].value);
              }
            }
          }
        } else {
          for (var j = 0; j < Repeat_data.length; j++) {
            if (Repeat_data[j].key === repeat_on_data) {
              day_array.push(Repeat_data[j].value);
            }
          }
        }
        schedule_list[i].repeat_on_days = day_array.toString();
        schedule_array.push(schedule_list[i]);
      }
    }
    this.setState({
      session_schedule_array: schedule_array,
    });
  };

  componentDidUpdate = () => {
    if (this.props.success_message === "SESSION_SCHEDULE_SUCCESS") {
      this.props.dispatch(ProviderActions.empty_message());
      toast.success("Session Added ..");
    } else if (this.props.success_message === "STUDIO_OBJECT_CHANGE_SUCCESS") {
      this.props.dispatch(ProviderActions.empty_message());
      this.componentDidMount();
    }
  };

  show_session_function = () => {
    this.setState({ show_session_section: true, show_session_list: false });
  };

  Add_Hide_show = () => {
    this.setState((prevState) => ({
      show_session_section: !prevState.show_session_section,
      show_session_list: !prevState.show_session_list,
    }));
  };

  schedule_submit_function = () => {
    this.setState({ show_session_section: false, show_session_list: true });
  };
  onchange_Checkbox_Handler = () => {
    this.setState((prevState) => ({
      hide_end_date: !prevState.hide_end_date,
    }));

    const { start_date } = this.state;
    this.setState({ end_date: start_date });
  };

  Radio_Onchange = () => {
    if (this.state.session_type === "0") {
      this.setState({ session_type: "1" });
    } else {
      this.setState({ session_type: "0" });
    }
  };

  Radio_diff_Onchange = (e) => {
    this.setState({ difficulty: e.target.value });
  };

  Onchange_Date_Function = (date) => {
    this.setState({ start_date: date });
  };
  Onchange_End_Date_Function = (date) => {
    this.setState({ end_date: date });
  };

  Onchange_Start_Time_Function = (date) => {
    this.setState({ start_time: date });
    setTimeout(() => {
      this.getTimeDifferenceFunction();
    }, 1000);
  };

  Onchange_End_Time_Function = (date) => {
    this.setState({ end_time: date });
    const { start_time } = this.state;

    setTimeout(() => {
      this.getTimeDifferenceFunction();
    }, 1000);
  };

  getTimeDifferenceFunction = () => {
    const { start_time, end_time } = this.state;
    var dateAndTime = Config.getTimeDiffenceInMinutes(start_time, end_time);
    this.setState({ duration_value: dateAndTime });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  RepeatOnClick = (d) => {
    let repeat_on_list = this.state.repeat_on_list;
    let arr1 = this.state.repeat_on;
    for (let i = 0; i < repeat_on_list.length; i++) {
      if (repeat_on_list[i]["key"] === d.key) {
        if (repeat_on_list[i]["status"] === "1") {
          repeat_on_list[i]["status"] = "0";
          for (let j = 0; j < arr1.length; j++) {
            if (arr1[j] === d.key) {
              if (j > -1) {
                arr1.splice(j, 1);
                this.setState({
                  repeat_on: arr1,
                });
              }
            }
          }
        } else {
          repeat_on_list[i]["status"] = "1";
          this.setState({
            repeat_on: [...this.state.repeat_on, d.key],
          });
        }
      }
    }
    this.setState({
      repeat_on_list: repeat_on_list,
    });
  };

  addSessionSubmit = (e) => {
    e.preventDefault();
    const Authorization = localStorage.getItem("Authorization");
    let start_date = this.state.start_date;
    start_date = Moment(start_date).format("YYYY-MM-DD HH:mm:ss");
    let end_date = this.state.end_date;
    end_date = Moment(end_date).format("YYYY-MM-DD HH:mm:ss");

    let start_time = this.state.start_time;
    start_time = Moment(start_time).format("YYYY-MM-DD HH:mm:ss");
    let end_time = this.state.end_time;
    end_time = Moment(end_time).format("YYYY-MM-DD HH:mm:ss");

    let { activity_ids,time_array_list } = this.state;
    let activities = activity_ids.toString();
    


    

    const {
      session_type,
      duration_value,
      title,
      description,
      trainer_name,
      capacity,
      kilo_cals,

      difficulty,
      btn_id,
      id,
    } = this.state;
    let location_id = this.props.studio_object_data.id;

    let { repeat_on } = this.state;
    repeat_on = repeat_on.toString();
    const form_Data1 = JSON.stringify({
      session_type,
      start_date,
      end_date,
      start_time,
      end_time,
      duration_value,
      title,
      description,
      trainer_name,
      capacity,
      kilo_cals,
      activities,
      difficulty,
      location_id,
      repeat_on,
      Authorization,
      btn_id,
      id,
      time_array_list
    });
    const formData = new FormData();
    formData.append("json", form_Data1);
    formData.append("time_json", time_array_list);

    this.props.dispatch(ProviderActions.AddSessionSchedule(formData));
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({ activities: selectedList });
    this.setState({
      // activity_ids: [...this.state.activity_ids, selectedItem.key],
      activity_ids: selectedItem.key,

      activity_names: [...this.state.activity_names, selectedItem.value],
    });

    console.log(this.state.activity_ids);
  };
  onRemove = (selectedList, removedItem) => {
    this.setState({ activities: selectedList });
    let array = this.state.activity_ids;
    let array1 = this.state.activity_names;

    const index = array.indexOf(removedItem.key);
    if (index > -1) {
      array.splice(index, 1);
      array1.splice(index, 1);
    }
    this.setState({ activity_ids: array, activity_names: array1 });
  };

  add_date_time_function = () => {
    var { start_time, time_array_list, end_time, duration_value } = this.state;
    var new_object = {
      start_time: start_time,
      end_time: end_time,
      duration_value: duration_value,
    };
    time_array_list.push(new_object);
    this.setState({ time_array_list: time_array_list });
  };

  remove_date_time_function = (y) => {
    const { time_array_list } = this.state;
    time_array_list.splice(y, 1);
    this.setState({ time_array_list: time_array_list });
  };

  duration_onchange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    const { start_time } = this.state;
    var returned_endate = Moment(start_time).add(e.target.value, "minutes");
    this.setState({ end_time: returned_endate });
  };

  render() {
    const { session_schedule_array, time_array_list } = this.state;
    return (
      <div>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <div className="schedule_dashboard_title">
              <h6>Schedule</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <div className="profile_time_dropdown_wrapper">
                  <Form.Control
                    as="select"
                    className="profile_dropdown_container profile_time_dropdown"
                    defaultValue="Date"
                  >
                    <option>Date</option>
                    <option>10 Mintues</option>
                    <option>15 Mintues</option>
                  </Form.Control>
                  <i
                    className="fa fa-caret-down profile_time_dropdown_icons "
                    aria-hidden="true"
                  ></i>
                </div>
              </Col>
              <Col md={4}>
                <div className="profile_time_dropdown_wrapper">
                  <Form.Control
                    as="select"
                    className="profile_dropdown_container profile_time_dropdown"
                    defaultValue="Session"
                  >
                    <option>Session</option>
                    <option>10 Mintues</option>
                    <option>15 Mintues</option>
                  </Form.Control>
                  <i
                    className="fa fa-caret-down profile_time_dropdown_icons "
                    aria-hidden="true"
                  ></i>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={4} className="add_new_session_container">
            <div onClick={this.Add_Hide_show}>
              <p>
                {this.state.show_session_section === true
                  ? "Hide Session"
                  : "Add New Session"}
              </p>
            </div>
          </Col>
        </Row>

        {this.state.show_session_list && (
          <div className="schedule_time_table_padding_top ">
            <Row>
              <Col md={7}>
                <div className="schedule_time_table_card_wrap">
                  <Table striped bordered hover responsive="xs sm md lg">
                    <thead>
                      <tr>
                        <th>Session</th>
                        <th>Time</th>
                        <th>Days</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {session_schedule_array.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.title}</td>
                            <td>
                              {Moment(item.start_time).format("hh:mm a")} -{" "}
                              {Moment(item.end_time).format("hh:mm a")}
                            </td>
                            <td>{item.repeat_on_days}</td>
                            <td onClick={this.show_session_function}>
                              <i
                                onClick={this.show_session_function}
                                class="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col md={5}>
                <div className="schedule_time_table_card_wrap">
                  <Table striped bordered hover responsive="xs sm md lg">
                    <thead>
                      <tr>
                        <th>Session</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>Capacity</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>boot camp (60m)</td>
                        <td>04.00 PM - 05.00 PM</td>
                        <td>16-03-2021</td>
                        <td>10</td>
                        <td onClick={this.show_session_function}>
                          <i
                            onClick={this.show_session_function}
                            class="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>

                      <tr>
                        <td>boot camp (60m)</td>
                        <td>04.00 PM - 05.00 PM</td>
                        <td>16-03-2021</td>
                        <td>10</td>
                        <td onClick={this.show_session_function}>
                          <i
                            onClick={this.show_session_function}
                            class="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {this.state.show_session_section && (
          <div className="schedule_time_table_padding_top">
            <Row>
              <Col md={12} lg={12} xl={12}>
                <div className="schedule_dashboard_title">
                  <h6>Add Session Schedule</h6>
                </div>
              </Col>
            </Row>

            <Form onSubmit={this.addSessionSubmit}>
              <Row>
                <Col md={12}>
                  <div className="schedule_session_type_wrap">
                    <Row>
                      <Col md={6}>
                        <div className="schedule_session_type_wrap_padding">
                          <div>
                            <h6>Session type</h6>

                            <div className="schedule_checkbox_wrap">
                              <Form.Group as={Row}>
                                <Col md={6} className="schedule_checkbox_main">
                                  <Form.Check
                                    type="radio"
                                    label="Recurring"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios1"
                                    checked={this.state.session_type === "0"}
                                    onClick={this.Radio_Onchange}
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="one time"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios2"
                                    checked={this.state.session_type === "1"}
                                    onClick={this.Radio_Onchange}
                                  />
                                </Col>
                              </Form.Group>
                            </div>
                             <div className="schedule_padding_bottom_wrapper">


                           
                            <Row >
                              <Col md={4}>
                                <div className="profile_date_wrapper ">
                                  <p className="Session_title_padding">{this.state.session_type !="1" ? "From Date" : "Date"}</p>
                                  <DatePicker
                                    name="appointment_time"
                                    selected={this.state.start_date}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                      this.Onchange_Date_Function(date);
                                    }}
                                  />
                                </div>
                              </Col>
                              {
                                this.state.session_type !="1" &&

                            
                               <React.Fragment>

                              <Col md={4}>
                                {!this.state.hide_end_date && (
                                  <div className="profile_date_wrapper">
                                    <p className="Session_title_padding">End Date</p>
                                    <DatePicker
                                      name="appointment_time"
                                      selected={this.state.end_date}
                                      minDate={new Date()}
                                      onChange={(date) => {
                                        this.Onchange_End_Date_Function(date);
                                      }}
                                    />
                                  </div>
                                )}
                              </Col>
                              <Col md={4} className="no_end_date_checkbox">
                                <div className="session_end_date_wrapper">
                                  {" "}
                                  <Form.Group id="formGridCheckbox">
                                    <Form.Check
                                      type="checkbox"
                                      label="No End Date"
                                      onChange={this.onchange_Checkbox_Handler}
                                      checked={this.state.hide_end_date}
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                              </React.Fragment>

                                }
                            </Row>
                            </div>

                            <div className="schedule_padding_bottom_wrapper">
                              
                            <Row>
                              <Col md={12} lg={12} xl={12}>
                                <div >
                                  <p className="Session_title_padding">Repeat On</p>
                                </div>
                              </Col>

                              <Col
                                md={6}
                                className="schedule_repeat_on_section_wrap"
                              >
                                <div className="schedule_repeat_on_section">
                                  {this.state.repeat_on_list.map((x, index) => {
                                    return (
                                      <p
                                        className={
                                          x.status === "1"
                                            ? "schedule_repeat_hightlight"
                                            : ""
                                        }
                                        onClick={() => {
                                          this.RepeatOnClick(x);
                                        }}
                                      >
                                        {x.value}
                                      </p>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>

                            </div>

                            <div className="schedule_start_end_time_wrapper">
                              <Row>
                                <Col md={4}>
                                  <p className="Session_title_padding">Start Time</p>
                                  <div className="profile_time_dropdown_wrapper">
                                    <DatePicker
                                      selected={this.state.start_time}
                                      onChange={(date) => {
                                        this.Onchange_Start_Time_Function(date);
                                      }}
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        4
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        22
                                      )}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={30}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                </Col>

                                <Col md={4} >
                                  <Form.Group controlId="formBasicEmail">
                                    
                                    <Form.Label>Duration(Minutes)</Form.Label>
                                    <Form.Control
                                      className="password_form"
                                      type="text"
                                      name="duration_value"
                                      value={this.state.duration_value}
                                      onChange={this.duration_onchange}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col md={4} className="Session_Add_button_center">
                                  <p
                                    onClick={this.add_date_time_function}
                                    className="profile_appointment_add_button"
                                  >
                                    Add Dates
                                  </p>
                                </Col>

                                {/* <Col md={6}>
                                  <p>End Time</p>
                                  <div className="profile_time_dropdown_wrapper">
                                    <DatePicker
                                      selected={this.state.end_time}
                                      onChange={(date) => {
                                        this.Onchange_End_Time_Function(date);
                                      }}
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        this.state.start_time.getHours()
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 30),
                                        23
                                      )}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={30}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                </Col> */}
                              </Row>
                            </div>

                            <div>
                              {time_array_list.length !== 0 && (
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Sno</th>
                                      <th>Start Time</th>
                                      <th>End Time</th>
                                      <th>Duration</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {time_array_list.map(
                                      (time_array_item, y) => (
                                        <tr key={y}>
                                          <td>{y + 1}</td>
                                          <td>
                                            {Moment(
                                              time_array_item.start_time
                                            ).format("hh:mm a")}
                                          </td>
                                          <td>
                                            {Moment(
                                              time_array_item.end_time
                                            ).format("hh:mm a")}
                                          </td>
                                          <td>
                                            {time_array_item.duration_value}
                                          </td>
                                          <td>
                                            <i
                                              onClick={() => {
                                                this.remove_date_time_function(
                                                  y
                                                );
                                              }}
                                              class="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                       
                        <div className="schedule_session_type_wrap_padding">
                        <p className="Session_title_padding">
                        Session Title
                        </p>
                          <Row>

                          
                            <Col md={8}>
                              <Multiselect
                                options={this.props.gym_activity_data}
                                selectedValues={this.state.activities}
                                onSelect={this.onSelect}
                                onRemove={this.onRemove}
                                displayValue="value"
                                showCheckbox={true}
                                singleSelect={true}
                                placeholder="Activities"
                              />
                            </Col>
                          </Row>

                          <div className="schedule_common_padding_top">
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Session Description</Form.Label>
                                  <Form.Control
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    as="textarea"
                                    rows={3}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="schedule_common_padding_top">
                            <Row>
                              <Col md={8}>
                                <Form>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Trainer Name</Form.Label>
                                    <Form.Control
                                      className="password_form"
                                      type="text"
                                      name="trainer_name"
                                      value={this.state.trainer_name}
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            </Row>
                          </div>

                          <div className="schedule_common_padding_top">
                            <Row>
                              <Col md={8}>
                                <p>Dificulty</p>
                                <div className="schedule_checkbox_wrap">
                                  <Form.Group as={Row}>
                                    <Col
                                      md={12}
                                      className="schedule_checkbox_main"
                                    >
                                      <Form.Check
                                        type="radio"
                                        label="Hard"
                                        name="difficulty1"
                                        id="difficulty1"
                                        value="0"
                                        checked={this.state.difficulty === "0"}
                                        onClick={this.Radio_diff_Onchange}
                                      />
                                      <Form.Check
                                        type="radio"
                                        label="Semi Hard"
                                        name="difficulty2"
                                        id="difficulty2"
                                        value="1"
                                        checked={this.state.difficulty === "1"}
                                        onClick={this.Radio_diff_Onchange}
                                      />
                                      <Form.Check
                                        type="radio"
                                        label="Easy"
                                        name="difficulty3"
                                        id="difficulty3"
                                        value="2"
                                        checked={this.state.difficulty === "2"}
                                        onClick={this.Radio_diff_Onchange}
                                      />
                                    </Col>
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="schedule_common_padding_top">
                            <Row>
                              <Col md={8}>
                                <Form>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Kilo Cals</Form.Label>
                                    <Form.Control
                                      className="password_form"
                                      type="text"
                                      name="kilo_cals"
                                      value={this.state.kilo_cals}
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            </Row>
                          </div>
                          <div className="schedule_common_padding_top">
                            <Row>
                              <Col md={8}>
                                <Form>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Capacity</Form.Label>
                                    <Form.Control
                                      className="password_form"
                                      type="text"
                                      name="capacity"
                                      value={this.state.capacity}
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="schedule_save_button">
                          <Button type="submit" variant="danger">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studio_object_data: state.ProviderReducer.studio_object_data,
    gym_activity_data: state.ProviderReducer.gym_activity_data,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,

    schedule_list: state.ProviderReducer.schedule_list,
  };
};

export default connect(mapStateToProps, null)(Schedule);
