import React, { Component } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
        <>
      <footer className="footer-section">
        <div className="footer-top-padding">
          <Container>
            <Row>
              <Col md={3} lg={3}>
                <div className="footer-logo">
                  <h3 className="footer-title">Machine Craft</h3>
                  <p className="footer-description">
                
                
                  Machine Craft has been launched with a clear vision to become multi-skilled and multi-dimensional IT service providers with a focus on high end strategic solutions along with the ultimate aim to evolve and become a leading One Stop internet strategy consulting company.
                  </p>
                </div>
                <div className="footer-social-wrap">
                  <ul>
                    <li>
                      <i class="fa fa-facebook"></i>
                    </li>
                    <li>
                      <i class="fa fa-twitter"></i>
                    </li>
                    <li>
                      <i class="fa fa-instagram"></i>
                    </li>
                    <li>
                      <i class="fa fa-youtube"></i>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col md={3} lg={3} className="footer-1 footer-mobile-padding">
                <div>
                  <h3 className="footer-title">IT Services</h3>

                  <div>
                    <ul>
                      <li>Software Development</li>
                      <li>Web Development</li>
                      <li>Analytic Solutions</li>
                      <li>Cloud and DevOps</li>
                      <li>Product Design</li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col md={3} lg={3} className="footer-mobile-padding">
                <div>
                  <h3 className="footer-title">Contact Info</h3>

                  <div>
                    <ul>
                      <li>No. 20a, Sivaji Street, Kuppuswamynagar, Manavalanagar, Thiruvallur, Chennai-602002
Tamilnadu , INDIA</li>
                      <li>(+91)8610 0096 37</li>
                      <li>info@mcinds.com</li>
                      <li>Opening Hours: 10:00 - 7:00</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={3} lg={3} className="footer-mobile-padding">
                <div>
                  <h3 className="footer-title">Newsletter</h3>
                  <p className="footer-description">
                    Sedut perspiciatis unde omnis iste natus error sitlutem acc
                    usantium doloremque laudantium totam remaperiaeaque ipsa
                    quae ab illo inventore veritatis
                  </p>
                </div>

                <div>
                  <Form>
                    <Form.Group
                      controlId="formBasicEmail"
                      className="newsletter-parent"
                    >
                      <Form.Label></Form.Label>
                      <Form.Control
                        className="newsletter_email"
                        type="email"
                        placeholder="Your email address"
                      />
                      <div className="newsletter_papper">
                        <i class="fa fa-paper-plane " aria-hidden="true"></i>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      
      </footer>
        <div className="footer-inner">

        <div className="text-center">
            <p>{new Date().getFullYear()} All Rights Reserved. Design & Developed MCINDS</p>
        </div>

    </div>
    </>
    );
  }
}
