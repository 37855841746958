import React from 'react';
import './SubmitButton.css';
import {Button} from 'react-bootstrap';



const GetStartedButton = (props) => {
    return (

        <div className="">
               <Button className="submit_button_wrap" variant="danger" type="submit">
                    Submit Now
                  </Button>
        </div>
       
    );
}

export default GetStartedButton;