import React, { Component } from "react";
import Header from "../../Dabblefit/Common/Header/Header";
import Footer from "../../Dabblefit/Common/Footer/Footer";
import { Col, Container, Row, Form,Button,Modal } from "react-bootstrap";
import "./MailVerification.css";
import * as ProviderActions from '../../redux/actions/Provider';
import { connect } from "react-redux";
import SuccessModal from '../../constants/SuccessModal/SuccessModal';
import Config from '../../../Config';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

 class ProviderSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username:'',
      password:'',
      client_ip:'',
      errors: {},
      type:'password',
      modal_show:false,
      forget_modal:false,

      mobile_number:'',
      email_id:'',
      errors: {},
      customer_id:''

 
    };
  }


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    let params = this.props.match.params.fp;
    let customer_id = params.split("&nm=")[1];
    this.MailVerificationFunction(customer_id);

}

MailVerificationFunction = (customer_id) => {
  const status = 1;
  const form_Data1 = JSON.stringify({ status, customer_id });
  this.props.dispatch(ProviderActions.MailVerficationAction(form_Data1));
};

  password_toogle_eye = () => this.setState(({type}) => ({type: type === 'text' ? 'password' : 'text'}));

  add_login_function =(e)=>
  { 
    e.preventDefault();
    const {username,password,client_ip} =this.state;
    this.props.dispatch(ProviderActions.SignInAction(username,password,client_ip));
  }
  componentDidUpdate = () => {
    if (this.props.success_message === "SUCCESS3") {
      this.props.history.push('/Provider')
      this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "RESET_SUCCESS") {
      toast.success("Reset Password link sent to your e-mail address. please follow the instructions..");
    this.setState({forget_modal:false,email_id:'',mobile_number:''});

      this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "RESET_ERROR") {
      toast.error(this.props.error_message);
    this.setState({forget_modal:false,email_id:'',mobile_number:''});

      this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "RESET_CATCH_ERROR") {
      toast.error(this.props.error_message);
     this.setState({forget_modal:false,email_id:'',mobile_number:''});
      this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "MAIL_VERIFICATION_SUCCESS") {
      toast.success("Mail Verification Sucess");
      this.setState({current_password:'',new_password:'',confirm_password:''});
      this.setState({customer_id:this.props.customer_id });
       this.props.dispatch(ProviderActions.empty_message());
  }else if (this.props.success_message === "MAIL_VERIFICATION_ERROR") {
      toast.error(this.props.error_message);
      this.setState({current_password:'',new_password:'',confirm_password:''});
      this.setState({ redirect: true });
       this.props.dispatch(ProviderActions.empty_message());
  }else if (this.props.success_message === "MAIL_VERIFICATION_CATCH_ERROR") {
      toast.error(this.props.error_message);
      this.setState({current_password:'',new_password:'',confirm_password:''});
      this.setState({ redirect: true });
       this.props.dispatch(ProviderActions.empty_message());
  }
  };

  forget_handle_close=()=>
  {
    this.setState({forget_modal:false,email_id:'',mobile_number:''});
  }
  forget_modalshow=()=>
  {
    this.setState({forget_modal:true});
  }
 
  onHandleTelephoneChange = (e) => {
    let mobile_number = e.target.value;
    mobile_number = Config.mobile_validation(mobile_number);
    this.setState({ [e.target.name]: mobile_number });
  };

  handletoemail = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.mail_validation(event.target.value);
    this.setState({ errors: errors });
  };



  forget_handleSubmit=(e)=> {
    console.log('reset form submit')
    e.preventDefault();
 
    const { email_id, mobile_number } = this.state;

    const form_Data1 = JSON.stringify({ email_id, mobile_number });
    this.props.dispatch(ProviderActions.PasswordResetAction(form_Data1));


  
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />
  }
  const {mobile_number} =this.state;
    return (
      <div >
        <Header />
<SuccessModal subtitle="Your process is ongoing once verified we will contact" title="Registration Is Done" modal_show={this.state.modal_show} handleClose={this.handleClose} modal_backdrop="static" modal_keyboard= {false} location_navigate="/"/>

      
        <Form onSubmit={this.add_login_function}>
        <section
          className="provider_sign_in_wrapper"
          id="provider_sign_in_wrapper"
        >
          <Container>
            <Row>

           

          
             
              <Col md={12} className="text-center">
                <div className="get_int_title">
                  <h6>Sign In</h6>
                </div>
                <div>
                  <Row>
                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="User Name"
                            name="username"
                            onChange={this.handleChange}
                          />
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>

                   

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type={this.state.type}
                            placeholder="Password"
                            name="password"
                            onChange={this.handleChange}
                          />
                           <span className="password__show" onClick={this.password_toogle_eye}>
                                        <i className={this.state.type === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                    </span>
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      
                      </div>
                    </Col>

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
               <div className="contact_dashboard_from_password">
                 <span onClick={this.forget_modalshow}>
                   Forget Password
                 </span>
               </div>
                    </Col>


                    
                  
                  </Row>
                  <div className="provider_signin_buttton_wrapper">
                <Button type="submit" variant="danger">Sign In</Button> 
          
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>


 
        </Form>

        <Modal  centered show={this.state.forget_modal} onHide={this.forget_handle_close}>
        <Form onSubmit={this.forget_handleSubmit}>

        <Modal.Header >
          <Modal.Title className="success_modal_title">Reset your password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <div className="get_in_form_wrapper">
        <p className="modal_create_para"></p>

                            
  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control
      type="text"
      placeholder="Mobile Number"
      name="mobile_number"
      placeholder="Mobile Number"
      value={mobile_number}
      onChange={this.onHandleTelephoneChange}
      maxLength={10}
      minLength={10}
      required
    />
    <div className="sign_form_icons">
    <i className="fa fa-mobile" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>

  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control 
     type="email"
     placeholder="Mail Id"
     name="email_id"
     value={this.state.email_id}
     onChange={this.handletoemail} />
    <div className="sign_form_icons">
    <i className="fa fa-envelope-o" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>
  <div className="text-danger text-center">
                          {this.state.errors.email}
                        </div>

                            </div>


            
            
            
          </Modal.Body>
        <Modal.Footer>
        <div className="get_in_register">
        <Button type="submit" variant="danger">Reset</Button> 
  </div>
      
        </Modal.Footer>
        </Form>
      </Modal>
        <Footer />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    gym_activity_data: state.ProviderReducer.gym_activity_data,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
    customer_id: state.ProviderReducer.customer_id,



  
  };
};

export default connect(mapStateToProps, null)(ProviderSignUp);