import React, { Component } from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import "./SoftwareDevelopment.css";
import HeaderImage from "../common/HeaderImageSection/HeaderImageSection";
import GetStartedButton from "../constant/ButtonBlue/GetstartedButton";
import images from "../constant/Images/images";
import HeadingTitle from "../common/HeadingTitle/HeadingTitle";
import PricePlan from "../common/PricePlan/Priceplan";
import * as AccountsData from "../constant/AccountData/AccountData";
import BrandSlider from '../common/BrandSlider/BrandSlider';


export default class SoftwareDevelopment extends Component {
  render() {
    return (
      <div>
        <Header />
        <HeaderImage
          title="Software Development"
          commonheaderinner="softwarebackground"
          secondtitle="Software Development"
        />

        <div className="software-section-intro">
          <Container>
            <Row>
              <Col md={6}>
                <div className="about-intro-content-wrap">
                  <div className="about-intro-content">
                    <span className="about-subtext">SOFTWARE DEVELOPMENT</span>
                    <h3 className="about-subtitle">
                      We Help to Implement Your Ideas into Automation
                    </h3>

                    <p className="about-subdescription">
                      Business ipsum dolor sit amet nsectetur cing elit. Suspe
                      ndisse suscipit sagittis leo sit met entum is not
                      estibulum dignity sim posuere cubilia durae. Leo sit met
                      entum cubilia crae. At vero eos accusamus et iusto odio
                      dignissimos provident nam libero tempore, cum soluta.
                    </p>
                  </div>
                  <div className="get-started-mobile">
                    <GetStartedButton
                      naviagtoLink="/Contact"
                      title="Contact Us"
                    />
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="about-intro-image">
                  <img
                    src={images.software_develop_intro}
                    className="img-fluid"
                    alt="vimkes"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="software-service-sectiond" id="software-service-sectiond">
          <Container>
            <Row>
              {
              AccountsData.SOFTWARE_DEVELOPMENT.map((software,softid)=>
              {
                return(
                  <Col md={4} key={softid}>
                  <div className="software-services-card">
                    <div className="software-services-card-img">
                    <img src={software.image_url} className="img-fluid" alt="Machine Craft" />
                    </div>
                    <div className="software-service-content">
                     <h2 className="software-services-title">{software.title}</h2>
                     <p className="software-services-desc">{software.description}</p>
  
                   </div>
                   </div>
  
                </Col>
                )
              })
              }
            
             
            </Row>
          </Container>
        </section>

        <section
          className="skillset-section-software"
          id="skillset-section-software"
        >
          <Container>
            <Row>
              <Col md={6}>
                <div className="skillset-image-wrapper">
                  <img
                    src={images.software_develop_skill}
                    className="img-fluid"
                    alt="Machine Craft"
                  />
                </div>
              </Col>

              <Col md={6} className="common_vertical_division">
                <div>
                  <HeadingTitle
                    heading="SKILL SET"
                    title="We Have the Hottest Skills to Hire For in 2021"
                  />
                  <div className="progressbar-main-wrapper">
                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Software Development</span>
                        <p>92%</p>
                      </div>
                      <ProgressBar animated variant="success" now={92} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Cyber Security</span>
                        <p>80%</p>
                      </div>
                      <ProgressBar animated variant="info" now={80} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Artificial Intelligence</span>
                        <p>95%</p>
                      </div>
                      <ProgressBar animated variant="danger" now={95} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Web Development</span>
                        <p>92%</p>
                      </div>
                      <ProgressBar animated variant="warning" now={78} />
                    </div>
                  </div>

                  <div>
                    <GetStartedButton title="Get Started" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>


      
        <PricePlan priceplan={AccountsData.OUR_PLAN_DATA} />


        <BrandSlider
        brand_section="product-section"
        brand_image_wrapper="product-image-wrapper" 
        brandimages={AccountsData.BRAND_SLIDER_IMAGES} />

        <Footer />
      </div>
    );
  }
}
