import React, { Component } from "react";

import Footer from "../../Dabblefit/Common/Footer/Footer";
import images from "../../constants/images";
import "./ProviderLayout.css";
import { Container, Row, Col } from "react-bootstrap";
import Home from "../Home/Home";
import Account from "../Account/Account";
import Contact from "../ContactUs/Contact";
import Package from "../Package/Package";
import Profile from "../Profile/Profile";
import Schedule from "../Schedule/Schedule";
import $ from "jquery";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as ProviderActions from "../../redux/actions/Provider";
import { toast } from "react-toastify";

const list_items = [
  {
    id: 1,
    title: "Home",
  },
  {
    id: 2,
    title: "Account",
  },
  {
    id: 3,
    title: "Profile",
  },
  {
    id: 4,
    title: "Schedule",
  },
  {
    id: 5,
    title: "Package",
  },
  {
    id: 6,
    title: "Contact Us",
  },
  {
    id: 7,
    title: "Log out",
  },
];
class HomeDasboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_list_id: 4,
      navExpanded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.dispatch(ProviderActions.get_studio_index_object(0));
    }, 1000);

    this.navbar_collapse_function();
  }

  componentWillMount() {
    this.navbar_collapse_function();
  }

  componentDidUpdate = () => {
    if (this.props.success_message === "LOGOUT_SUCCESS") {
      this.props.dispatch(ProviderActions.empty_message());
      localStorage.clear();
      this.props.history.push("/");
    } else if (this.props.success_message === "PROFILE_IMAGE_UPLOAD") {
      this.props.dispatch(ProviderActions.GetStudioData());
      this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "COVER_IMAGE_UPLOAD") {
      this.props.dispatch(ProviderActions.GetStudioData());
      this.props.dispatch(ProviderActions.empty_message());
    }
    
  };

  navbar_collapse_function = () => {
    $(document).click(function (e) {
      if (
        $(".collapse").hasClass("show") &&
        !$("nav").is(e.target) &&
        $("nav").has(e.target).length === 0
      ) {
        $(".navbar-toggler").click();
      }
    });
  };
  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };
  navigate_handle_list = (id) => {
    this.setState({ active_list_id: id });

    if (id.toString() === "7") {
      this.logoutfunction();
    }
    this.navbar_collapse_function();
  };
  logoutfunction = () => {
    const Authorization = localStorage.getItem("Authorization");
    const login_history_id = localStorage.getItem("login_history_id");

    const form_Data1 = JSON.stringify({ Authorization, login_history_id });
    this.props.dispatch(ProviderActions.logoutAction(form_Data1));
  };

  branch_location_onchange = (e) => {
    this.props.dispatch(ProviderActions.get_studio_object(e.target.value));
  };

  render() {
    const { active_list_id } = this.state;
    return (
      <section className="home_dasboard_section" id="home_dasboard_section">
        <div className="d-block d-sm-none">
          <Navbar
            bg="light"
            className="fixed-top"
            expand="lg"
            onToggle={this.setNavExpanded}
            expanded={this.state.navExpanded}
          >
            <Navbar.Brand href="/">
              <img
                src={images.dabble_logo}
                alt="Dabblefit"
                className="img-fluid"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto ml-auto" onClick={this.setNavClose}>
                {list_items.map((item, index) => {
                  return (
                    <Nav.Link
                      key={item.id}
                      onClick={() => {
                        this.navigate_handle_list(item.id);
                      }}
                      className={
                        active_list_id === item.id ? "highlight_color1" : ""
                      }
                    >
                      {" "}
                      {item.title}
                    </Nav.Link>
                  );
                })}
              </Nav>
              <Form inline>
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2"
                />
                <Button variant="outline-success">Search</Button>
              </Form>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="home_dasboard_wrapper">
          <Container fluid>
            <Row>
              <Col md={2} lg={2} xs={12} sm={1} className="d-none d-sm-block">
                <div className="sticky-top">
                  <div className="home_dasboard_sidenav_image">
                    <img
                      src={images.dabble_logo}
                      alt="Dabblefit"
                      className="img-fluid"
                    />
                  </div>

                  <div className="home_dashbord_sidenav_unorderlist">
                    <ul>
                      {list_items.map((item, index) => {
                        return (
                          <li
                            key={item.id}
                            onClick={() => {
                              this.navigate_handle_list(item.id);
                            }}
                            className={
                              active_list_id === item.id
                                ? "highlight_color"
                                : ""
                            }
                          >
                            {item.title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col
                md={10}
                lg={10}
                xs={12}
                sm={11}
                className="p-0 home_dasboard_main_wrapper"
              >
                <div className="home_dasbord_period_dropdown home_dashboard_dropdown_inner">
                  <div className="home_dashbord_dropdown_wrapper">
                    <Form.Control
                      className="dasboard_section_dropdown input-lg"
                      as="select"
                      onChange={this.branch_location_onchange}
                    >
                      {this.props.studio_list.map((item, index) => {
                        return (
                          <option id={item.id} key={item.id} value={item.id}>
                            {item.branch_location}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <i
                      className="fa fa-caret-down period_icons "
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <div className="home_dasboard_main_view home_dasboard_main_view1">
                  {active_list_id === 1 && <Home />}
                  {active_list_id === 2 && <Account />}
                  {active_list_id === 3 && <Profile />}
                  {active_list_id === 4 && <Schedule />}

                  {active_list_id === 5 && <Package />}
                  {active_list_id === 6 && <Contact />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studio_list: state.ProviderReducer.studio_list,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
  };
};

export default connect(mapStateToProps, null)(HomeDasboard);
