import images from "../../constant/Images/images";

export const SERVICES_CARD_DATA=[
    {
      id:1,
      name:'ajith',
      image_url:images.highquality,
      image_hover:images.highqualityTransparent,
      title:'Software Development',
      addclass:"service-card-first",
      serial_number:"01"
    },
    {
      id:2,
      name:'ajith',
      image_url:images.apporach,
      image_hover:images.apporachTransparent,
      title:'Web Development',
      addclass:"service-card-second",
      serial_number:"02"
  
    },
    {
      id:3,
      name:'ajith',
      image_url:images.team,
      image_hover:images.teamTransparent,
      title:'Analytic Solutions',
      addclass:"service-card-third",
      serial_number:"03"
  
  
      
    },
    {
      id:4,
      name:'ajith',
      image_url:images.shield,
      image_hover:images.shieldTransparent,
      title:'Clould & DevOps',
      addclass:"service-card-four",
      serial_number:"04"
  
  
    },
    {
      id:5,
      name:'ajith',
      image_url:images.team,
      image_hover:images.teamTransparent,
      title:'Product & Design',
      addclass:"service-card-third",
      serial_number:"05"
    },
    {
      id:6,
      name:'ajith',
      image_url:images.team,
      image_hover:images.teamTransparent,
      title:'Database Administrator',
      addclass:"service-card-first",
      serial_number:"06"
    },
  ]

  export const SERVICES_CARD=[
    {
      id:1,
      name:'ajith',
      image_url:images.highquality,
      image_hover:images.highqualityTransparent,
      title:'High-quality Code',
      addclass:"service-card-first",
      margin_top_class:'service-card-wrapper-margintop',
      description:' At vero eos et accusamus etiusto odio praesentium accusamus etiusto',
  
    },
    {
      id:2,
      name:'ajith',
      image_url:images.apporach,
      image_hover:images.apporachTransparent,
      title:'Agile Approach',
      addclass:"service-card-second",
  
    },
    {
      id:3,
      name:'ajith',
      image_url:images.team,
      image_hover:images.teamTransparent,
      title:'Full-stack teams',
      addclass:"service-card-third",
      serial_number:"03"
  
  
      
    },
    {
      id:4,
      name:'ajith',
      image_url:images.shield,
      image_hover:images.shieldTransparent,
      title:'Flexibility',
      addclass:"service-card-four",
      serial_number:"04"
  
  
    },
   
  ]


export const BRAND_SLIDER_IMAGES=[
    {
        id:1,
        images_url:images.home_brand1
    },
    {
        id:2,
        images_url:images.home_brand2
    },
    {
        id:3,
        images_url:images.home_brand3
    },
    {
        id:4,
        images_url:images.home_brand4
    },
    {
        id:5,
        images_url:images.home_brand5
    }
]

export const WHY_CHOOSE_DATA=[
    {
        id:1,
        title:'First Growing Process',
        description:'At vero eos et accusamus etiusto odio praesentium.',
        image_url:images.why_image1
    },
    {
        id:2,
        title:'Clean code',
        description:'At vero eos et accusamus etiusto odio praesentium.',
        image_url:images.why_image2
    },
    {
        id:3,
        title:'Well Documentation',
        description:'At vero eos et accusamus etiusto odio praesentium.',
        image_url:images.why_image3
    }
]

export const SCHEDULE_DATA=[
  {
      id:1,
      title:'Email',
      description:'info@mcinds.com',
      image_url:images.schedule_email
  },
  {
      id:2,
      title:'Call Us',
      description:'+91 9524647021',
      image_url:images.schedule_map
  },
  {
      id:3,
      title:'Office Address',
      description:'#62, Station Rd, Radha Nagar, Chromepet, Chennai, Tamil Nadu 600047',
      image_url:images.schedule_phone
  }
]



export const WORKING_FLOW = [
  {
    id: 1,
    image_url:images.about_workflow1,
    title: "Discovery",
  },
  {
    id: 2,
    image_url:images.about_workflow2,
    title: "Planning",
  },
  {
    id: 3,
    image_url:images.about_workflow3,
    title: "Execute",
  },
  {
    id: 4,
    image_url:images.about_workflow4,
    title: "Deliver",
  },
];

export const OUR_PLAN_DATA=[
  {
    id: 1,
    image_url:images.about_plan1,
    title: "SILVER",
    activeClass:''
  },
  {
    id: 2,
    image_url:images.about_plan2,
    title: "GOLD",
    activeClass:'active_price_plan'

  },
  {
    id: 3,
    image_url:images.about_plan3,
    title: "PLATINUM",
    activeClass:''

  },
]

export const SOFTWARE_DEVELOPMENT=[
  {
    id: 1,
    image_url:images.software_expert,
    title: "Creative Ideas",
    description:'At vero eos et accusamus etiusto odio praesentium'
  },
  {
    id: 2,
    image_url:images.software_chart,
    title: "First Growing Process",
    description:'At vero eos et accusamus etiusto odio praesentium'


  },
  {
    id: 3,
    image_url:images.software_light,
    title: "Creative Ideas",
    description:'At vero eos et accusamus etiusto odio praesentium'


  },
]