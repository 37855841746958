import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./Package.css";

export default class Package extends Component {
  render() {
    return (
      <div className="package_dashboard" id="package_dashboard">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Row>
              <Col md={12} lg={12} xl={12}>
                <div className="package_dashboard_main_wrapper">
                  <h6>Packages</h6>

                  <div className="package_dashboard_content">
                    <p>Offer session packages directly Dabblefit customers </p>
                    <ul>
                      <li>
                        Dabblefit will offer your packages at 10% discount &#38;
                        aff fees{" "}
                      </li>
                    </ul>
                  </div>

                  <Row>
                    <Col md={8}>
                      <div className="package_dashboard_wrapper">
                        <Row>
                          <Col md={8}>
                            <div>
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Enter Pack Quantity</Form.Label>
                                  <Form.Control
                                    className="password_form"
                                    type="text"
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={8}>
                            <div>
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Retail Price</Form.Label>
                                  <Form.Control
                                    className="password_form"
                                    type="text"
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} className="package_button_wrapper">
                            <div className="package_button_container">
                              <p>Cancel</p>
                            </div>

                            <div className="package_button_container">
                              <p>Save</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <div className="dummy_paragraph_height">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
