import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./OurBlogs.css";
import { Container, Row, Col } from "react-bootstrap";
import {Blog_list} from '../../constants/AccountData'

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      activeClass:''
    };
  }
componentDidMount()
{
  window.scrollTo(0,0)
}

  render() {
    return (
      <div className="our_blog_section" id="our_blog_section">
        <Header />

        <section
          className="our_blog_section_section" id="our_blog_section_section">
          <div className="our_blog_section_section_overlay"></div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="our_blog_section_section_content">
                  <h1>
                   OUR<span>BLOGS</span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our_blog_card_section" id="our_blog_card_section"> 

        <Container>
          {
            Blog_list.map((item,index)=>
            {
              if (index % 2 === 0) {
                return(
                  <Row className="our_blog_padding_bottom">
                  <Col md={12}>
                     <div className="our_blog_card">
  
                    <Row>

                    
                         <Col md={5} xs={12}>
                            <div className="our_blog_image_wrap">
                            <img src={item.image_url} alt="dabblefit" />
                            </div>
                        </Col>
                        <Col md={7} xs={12} className="our_blog_content_wrap">
  
                            <div className="our_blog_title">
                                <h4>THE HEALTHIER WAY OF LIVING</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      
                                <div className="our_blog_author">
                              <h6> By: Shreya Sajid, Dec 1 2020</h6>
                              </div>
                            </div>

  
                            <Row>
                  <Col md={12}>
                    <div className="gallery_view">
                      <p>View More</p>
                      <div className="view_triangle"></div>
                    </div>
                  </Col>
                </Row>               
                        </Col>
                    </Row>
                    </div>
                  </Col>
              </Row>
  
              
                )
            } else {
              return(

                <Row className="our_blog_padding_bottom">
                <Col md={12}>
                   <div className="our_blog_card">

                  <Row>
                     
                      <Col md={7} xs={12} className="our_blog_content_wrap first_section_mobile">

                          <div className="our_blog_title">
                              <h4>THE HEALTHIER WAY OF LIVING</h4>
                              
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                              <div className="our_blog_author">
                              <h6> By: Shreya Sajid, Dec 1 2020</h6>
                              </div>
                          </div>

                          <Row>
                <Col md={12}>
                  <div className="gallery_view">
                    <p>View More</p>
                    <div className="view_triangle"></div>
                  </div>
                </Col>
              </Row>

                 
                          
                      </Col>
                      <Col md={5} xs={12}>
                          <div className="our_blog_image_wrap1 second_section_mobile">
                          <img src={item.image_url} alt="dabblefit" />
                          </div>
                      </Col>
                  </Row>


                  
                  </div>

                </Col>
            </Row>

            
              )
            }
              
            })
          }

        </Container>

        </section>


        

     


        <Footer />
      </div>
    );
  }
}
