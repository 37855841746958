import React, { Component } from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import { Container, Row, Col, Form, } from "react-bootstrap";
import "./Contact.css";
import SubmitButton from '../constant/SubmitButton/SubmitButton';
import HeaderImage from '../common/HeaderImageSection/HeaderImageSection';

export default class Contact extends Component {
  render() {
    return (
      <div>
        <Header />
        <HeaderImage  title="Contact" commonheaderinner="conactbackground" secondtitle="Contact" />
        <section className="contact-form">
          <Container>
            <Row>
              <Col md={5}>
                <div className="contact-card-form">
                  <div>
                    <span className="subtext-title">LET'S TALK</span>
                    <h3 className="subtext-heading">
                      Speak With Expert Engineers.
                    </h3>
                  </div>
                  <div className="contact-list-padding">
                    <div className="contact-list-icons-wrap">
                      <div className="contact-list-icons">
                        <i class="fa fa-home" aria-hidden="true"></i>
                      </div>
                      <div>
                        <h6>Email:</h6>
                        <span>info@mcinds.com</span>
                      </div>
                    </div>
                    <div className="contact-list-icons-wrap">
                      <div className="contact-list-icons">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      <div>
                        <h6>Location:</h6>
                        <span>Chennai</span>
                      </div>
                    </div>

                    <div className="contact-list-icons-wrap">
                      <div className="contact-list-icons">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>
                      <div>
                        <h6>Phone:</h6>
                        <span>+91 9962208237</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className="contact-form-section">
                  <div>
                    <span className="subtext-title subtext-color">
                      GET IN TOUCH
                    </span>
                    <h3 className="subtext-heading subtext-heading-color">
                      Fill The Form Below
                    </h3>
                  </div>

                  <Row>
                    <Col md={6}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col md={6}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col md={6}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Phone Number"
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col md={6}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control type="text" placeholder="Website" />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label></Form.Label>
                        <Form.Control
                          placeholder="your Message Here"
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div>
                    <SubmitButton />                
                  </div>
               
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="contact-map-function">
          <Container>
            <Row>
              <Col md={12}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.874576359157!2d79.91993481464672!3d13.10713119076709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a528f6fcd0e88c1%3A0x2ffc67a9f1eb9838!2sMachine%20Craft%20Industries!5e0!3m2!1sen!2sin!4v1626492791064!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    );
  }
}
