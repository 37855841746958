import React, { Component } from "react";
import "./Homepage.css";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";

import { Container, Col, Row, ProgressBar,Form,Button } from "react-bootstrap";
import images from "../constant/Images/images";
import GetStartedButton from "../constant/Button/GetstartedButton";

import HeadingTitle from "../common/HeadingTitle/HeadingTitle";
import DescriptionList from '../common/DescriptionList/DescriptionList';
import {
  SERVICES_CARD_DATA,
  BRAND_SLIDER_IMAGES,
  SERVICES_CARD,
  WHY_CHOOSE_DATA,
  SCHEDULE_DATA
} from "../constant/AccountData/AccountData";
import  Blog from '../common/BlogSlider/Blog';
import BrandSlider from '../common/BrandSlider/BrandSlider';
export default class Homepage extends Component {
  render() {
  
    return (
      <div className="wrapper">
        <Header />

        <section className="home-section" id="home">
          <Container>
            <Row>
              <Col md={6} className="common_vertical_division">
                <div>
                  <h6 className="home-heading">
                    We Are Digital Agency & Technology Solution
                  </h6>

                  <p className="home_description">
                    We are leading technology solutions providing company all
                    over the world doing over 40 years.
                  </p>

                  <div>
                    <GetStartedButton title="Get Started" />
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div>
                  <img
                    src={images.home_banner}
                    className="img-fluid"
                    alt="Vimkes Technologoy"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>


        <BrandSlider
        brand_section="brand-section"
        brand_image_wrapper="brand-image-wrapper" 
        brandimages={BRAND_SLIDER_IMAGES} />



        {/* <section className="brand-section" id="brand-section">
          <Container>
            <Row>
              <Col md={12}>
                <Slider {...settings}>
                  {BRAND_SLIDER_IMAGES.map((brand_data, brandid) => {
                    return (
                      <div key={brandid} className="brand-image-wrapper">
                        <img
                          src={brand_data.images_url}
                          className="img-fluid"
                          alt="brand_images"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section> */}

        <section className="about-section" id="about-section">
          <Container>
            <Row>
              <Col md={5}>
                <div className="about-section-content-wrapper">
                  <HeadingTitle
                    heading="ABOUT US"
                    title="Over 25+ Years Helping Brands to Reach Full Potential"
                    description="  Over 25 years working in IT services developing software
                applications and mobile apps for clients all over the
                world. From its medieval origins to the digital era, learn
                everything there is to know about the ubiquitous
               lorem ipsum passage. Creation timelines for the
                standard <em>lorem ipsum passage vary, with some
                citing the 15th century."
                  />
                </div>

                <Row>
                  <Col md={6} className="pb-3">
                    <div className="count-number-wrapper">
                      <span className="count-number counter">450</span>
                      <span className="count-prefix">+</span>
                    </div>
                    <span className="count-title">Happy Client</span>
                  </Col>

                  <Col md={6}>
                    <div className="project-number-wrapper">
                      <span className="count-number counter">750</span>
                      <span className="count-prefix">+</span>
                    </div>
                    <span className="count-title">Project Delivered</span>
                  </Col>
                </Row>
              </Col>

              <Col md={7}>
                <Row>
                  {SERVICES_CARD.map((service_data, service_id) => {
                    return (
                      <Col md={6}>
                        <div
                          key={service_id}
                          className={service_data.margin_top_class}
                        >
                          <div
                            className={`service-card-wrapper ${service_data.addclass}`}
                          >
                            <div className="service-card-image">
                              <img
                                src={service_data.image_url}
                                className="img-fluid service-original-image"
                                alt="Machine Craft"
                              />
                              <img
                                src={service_data.image_hover}
                                className="img-fluid service-hover-image"
                                alt="Machine Craft"
                              />
                            </div>
                            <div className="service-text">
                              <div className="service-title">
                                <h3>{service_data.title}</h3>
                              </div>
                              <p className="service-txt">
                                At vero eos et accusamus etiusto odio
                                praesentium accusamus etiusto.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="skillset-section" id="skillset-section">
          <Container>
            <Row>
              <Col md={6}>
                <div className="skillset-image-wrapper">
                  <img
                    src={images.skillset_bg}
                    className="img-fluid"
                    alt="Machine Craft"
                  />
                </div>
              </Col>

              <Col md={6} className="common_vertical_division">
                <div>
                  <HeadingTitle
                    heading="SKILL SET"
                    title="We Have the Hottest Skills to Hire For in 2021"
                  />
                  <div className="progressbar-main-wrapper">
                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Software Development</span>
                        <p>92%</p>
                      </div>
                      <ProgressBar animated variant="success" now={92} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Cyber Security</span>
                        <p>80%</p>
                      </div>
                      <ProgressBar animated variant="info" now={80} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Artificial Intelligence</span>
                        <p>95%</p>
                      </div>
                      <ProgressBar animated variant="danger" now={95} />
                    </div>

                    <div className="pb-3">
                      <div className="progressbar-wrapper">
                        <span>Web Development</span>
                        <p>92%</p>
                      </div>
                      <ProgressBar animated variant="warning" now={78} />
                    </div>
                  </div>

                  <div>
                    <GetStartedButton title="Get Started" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="services-section" id="services-section">
          <Container>
            <Row>
              <Col md={12}>
                <div className="service-margin-section">
                  <Row>
                    <Col md={6}>
                      <HeadingTitle
                        heading="SERVICES"
                        title="What Solutions We Provide to Our Valued Customers"
                      />
                    </Col>

                    <Col md={6} className="service-getstarted-button">
                      <div>
                        <GetStartedButton title="Get Started" />
                      </div>
                    </Col>
                  </Row>

                  <div className="service-section-cardwrap">
                    <Row>
                      {SERVICES_CARD_DATA.map((card, cardid) => {
                        return (
                          <Col
                            key={cardid}
                            md={4}
                            
                            className="service-card-marginbottom"
                          >
                            <div
                              className={`service-card-wrapper ${card.addclass}`}
                            >
                              <div className="service-card-image">
                                <img
                                  src={card.image_url}
                                  className="img-fluid service-original-image"
                                  alt="Machine Craft"
                                />
                                <img
                                  src={card.image_hover}
                                  className="img-fluid service-hover-image"
                                  alt="Machine Craft"
                                />
                              </div>
                              <div className="service-card-serial">
                                {card.serial_number}
                              </div>
                              <div className="service-text">
                                <div className="service-title">
                                  <h3>{card.title}</h3>
                                </div>
                                <p className="service-txt">
                                  At vero eos et accusamus etiusto odio
                                  praesentium accusamus etiusto. At vero eos et
                                  accusamus etiusto odio praesentium accusamus
                                  etiusto.
                                </p>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="why-choose-section" id="why-choose-section">
          <Container>
            <Row>
              <Col md={6}>
                <div className="why-choose-image-wrapper">
                  <img
                    src={images.why_choose_image}
                    className="img-fluid"
                    alt="Machine Craft"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <HeadingTitle
                    heading="WHY CHOOSE US"
                    title="We Create Result-Oriented Dynamic Applications"
                    description="Over 25 years working in IT services developing software applications and mobile apps for clients all over the world"
                  />
                </div>
               

                <div className="list-details-wrapper">
                {
                  WHY_CHOOSE_DATA.map((why_data,whyid)=>
                  {
                    return(

                      <DescriptionList 
                      id={whyid}
                      image_url={why_data.image_url}
                      title={why_data.title}
                      description={why_data.description}/>
                    )
                  })
                }
                </div>


              </Col>
            </Row>
          </Container>
        </section>


        <section className="schedule-section" id="schedule-section">
         

         
          <Container>
          
            <Row >
              <Col md={8}>

              <div className="let-talk-padding">
                  <HeadingTitle
                    heading="LET'S TALK"
                    title="Speak With Expert Engineers."
                    description="From its medieval origins to the digital era, learn everything there
                    is to know about the ubiquitous lorem ipsum passage."
                  />
                </div>

                <div className="list-details-wrapper">
                {
                  SCHEDULE_DATA.map((why_data,whyid)=>
                  {
                    return(
                  <DescriptionList  id={whyid} image_url={why_data.image_url} title={why_data.title} description={why_data.description}/>
                    )
                  })
                }
                </div>

              </Col>

              <Col md={4}>
                <div className="let-talk-padding">

                <div className="schedule-card-form">
                  <h3 className="schedule-title">Schedule Appointment</h3>
                  <p className="schedule-description"> We here to help you 24/7 with experts</p>



                  <div className="mt-3">
                  <Form>
  <Form.Group controlId="formBasicEmail">
    <Form.Control type="tex" placeholder="Name" />
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Control type="email" placeholder="E-mail" />
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Control type="number" placeholder="Phone Number" />
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Control className="text_areadescription" as="textarea" rows={3} />
  </Form.Group>
  <Button variant="primary w-100" type="submit">
    Submit Now
  </Button>
</Form>
                  </div>
                  </div>

                </div>

              

              </Col>
            </Row>
         

          </Container>
        </section>

        <Blog />

        <BrandSlider
        brand_section="product-section"
        brand_image_wrapper="product-image-wrapper" 
        brandimages={BRAND_SLIDER_IMAGES} />

        <Footer />
      </div>
    );
  }
}
