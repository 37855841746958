const home_banner=require('../../../../assets/vimkes/homepage/banner/home-banner.png');

const header_logo=require('../../../../assets/vimkes/homepage/logo/logo.png');


const home_brand1=require('../../../../assets/vimkes/homepage/brand/brand1.png');
const home_brand2=require('../../../../assets/vimkes/homepage/brand/brand2.png');
const home_brand3=require('../../../../assets/vimkes/homepage/brand/brand3.png');
const home_brand4=require('../../../../assets/vimkes/homepage/brand/brand4.png');
const home_brand5=require('../../../../assets/vimkes/homepage/brand/brand5.png');



const highquality=require('../../../../assets/vimkes/homepage/service/highquality.png');
const highqualityTransparent=require('../../../../assets/vimkes/homepage/service/highqualityTransparent.png');
const apporach=require('../../../../assets/vimkes/homepage/service/apporach.png');
const apporachTransparent=require('../../../../assets/vimkes/homepage/service/apporachTransparent.png');
const team=require('../../../../assets/vimkes/homepage/service/team.png');
const teamTransparent=require('../../../../assets/vimkes/homepage/service/teamTransparent.png');
const shield=require('../../../../assets/vimkes/homepage/service/shield.png');
const shieldTransparent=require('../../../../assets/vimkes/homepage/service/shieldTransparent.png');


const skillset_bg=require('../../../../assets/vimkes/homepage/skillset/bg.png');

const why_choose_image=require('../../../../assets/vimkes/homepage/whychoose/bg.png');
const why_image1=require('../../../../assets/vimkes/homepage/whychoose/why-image1.png');
const why_image2=require('../../../../assets/vimkes/homepage/whychoose/why-image2.png');
const why_image3=require('../../../../assets/vimkes/homepage/whychoose/why-image3.png');



const schedule_email=require('../../../../assets/vimkes/homepage/schedule/email.png');
const schedule_map=require('../../../../assets/vimkes/homepage/schedule/map.png');
const schedule_phone=require('../../../../assets/vimkes/homepage/schedule/phone-call.png');




const about_banner=require('../../../../assets/vimkes/about/bg/home.png');
const about_dot=require('../../../../assets/vimkes/about/bg/dot.png');


const about_workflow1=require('../../../../assets/vimkes/about/workflow/flow1.png');
const about_workflow2=require('../../../../assets/vimkes/about/workflow/flow2.png');
const about_workflow3=require('../../../../assets/vimkes/about/workflow/flow3.png');
const about_workflow4=require('../../../../assets/vimkes/about/workflow/flow4.png');



const about_plan1=require('../../../../assets/vimkes/about/priceplan/plan1.png');
const about_plan2=require('../../../../assets/vimkes/about/priceplan/plan2.png');
const about_plan3=require('../../../../assets/vimkes/about/priceplan/plan3.png');


const software_develop_intro=require('../../../../assets/vimkes/softwaredevelopement/intro/bg.jpg');
const software_develop_skill=require('../../../../assets/vimkes/softwaredevelopement/skillset/bg.png');

const software_expert=require('../../../../assets/vimkes/softwaredevelopement/service/expert.png');
const software_chart=require('../../../../assets/vimkes/softwaredevelopement/service/chart.png');
const software_light=require('../../../../assets/vimkes/softwaredevelopement/service/light.png');














export default
{
    home_banner,
    home_brand1,
    home_brand2,
    home_brand3,
    home_brand4,
    home_brand5,
    header_logo,
    highquality,
    highqualityTransparent,
    apporach,
    apporachTransparent,
    team,
    teamTransparent,
    shield,
    shieldTransparent,

    skillset_bg,
    why_choose_image,
    why_image1,
    why_image2,
    why_image3,

    schedule_email,
    schedule_map,
    schedule_phone,
    about_banner,
    about_dot,
    about_workflow1,
    about_workflow2,
    about_workflow3,
    about_workflow4,

    about_plan1,
    about_plan2,
    about_plan3,
    software_develop_intro,
    software_develop_skill,

    software_expert,
    software_chart,
    software_light


}