import {
  GYM_ACTIVITIES,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  CUSTOMER_ID,
  CUSTOMER_DATA,
  STUDIO_DATA,
  STUDIO_OBJECT,
  STUDIO_INDEX_OBJECT,
  STUDIO_LIST_UPDATE,
  IMAGE_ADDRESS_LIST,
  CATEGORY_LIST,
  SCHEDULE_LIST
} from "../actions/Provider";

const initialState = {
  gym_activity_data: [],
  success_message: "",
  error_message: "",
  customer_id: "",
  customer_object_data: {},
  studio_list: [],
  studio_object_data: {},
  images_address_list:[],
  category_list_data:[],
  schedule_list:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MESSAGE: {
      return {
        ...state,
        success_message: action.success_title,
      };
    }

    case SCHEDULE_LIST: {
      return {
        ...state,
        schedule_list: action.schedule_list,
      };
    }
    case ERROR_MESSAGE: {
      return {
        ...state,
        error_message: action.error_title,
      };
    }
    case CUSTOMER_ID: {
      return {
        ...state,
        customer_id: action.customer_verfication_id,
      };
    }

    case GYM_ACTIVITIES: {
      return {
        ...state,
        gym_activity_data: action.gym_activity,
      };
    }

    case CUSTOMER_DATA: {
      return {
        ...state,
        customer_object_data: action.customer_object,
      };
    }

    case STUDIO_DATA: {
      return {
        ...state,
        studio_list: action.studio_data_list,
      };
    }

    case IMAGE_ADDRESS_LIST: {
      return {
        ...state,
        images_address_list: action.img_address_list,
      };
    }



    case STUDIO_OBJECT: {
      let studio_id = action.studio_object_id;
      let studio_object_data = state.studio_list.find(
        (item) => item.id.toString() === studio_id
      );
      return {
        ...state,
        studio_object_data: studio_object_data,
      };
    }

    case STUDIO_LIST_UPDATE: {
      let studio_updated_object = action.studio_update_object;

      let studio_id = studio_updated_object.id.toString();
      let studio_total_list = state.studio_list;

      for (let i = 0; i < studio_total_list.length; i++) {
        if (studio_total_list[i].id === studio_id) {
          studio_total_list[i] = studio_updated_object;
        }
      }
      return {
        ...state,
        studio_list: studio_total_list,
        studio_object_data: studio_updated_object,
      };
    }

    case STUDIO_INDEX_OBJECT: {
      let studio_index_id = action.studio_index;

      let studio_object_index_data = state.studio_list[studio_index_id];

      return {
        ...state,
        studio_object_data: studio_object_index_data,
      };
    }

    case CATEGORY_LIST: {
      return {
        ...state,
        category_list_data: action.category_list,
      };
    }
    
  }

  return state;
};
