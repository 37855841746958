import React, { Component } from 'react';
import {Row,Col,Container} from 'react-bootstrap';
import './Footer.css'
import images from '../../../constants/images'
import {Link} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class Footer extends Component {
  render() {
    return (
     <section className="footer_section common_section_top" id="footer_section">
   <ToastContainer  position="top-right" />
         <div className="footer_wrapper">
           <Container>
             <Row>
             
               <Col md={12}>
                 <Row>
                   <Col md={6}>
                     <img src={images.footer_logo} alt="footer_logo" className="img-fluid" />
                   </Col>
                   <Col md={6} className="footer_unoder_list">
                     <Row>
                       <Col md={6} className="footer_border_wrap">
                         <div className="footer_unorder_wrap">
                           <ul>
                             <li>HOME</li>
                             <li>ABOUT US</li>
                             <li><Link to="/OurBlogs">BLOGS</Link></li>
                             <li>SIGN UP</li>

                           </ul>
                         </div>

                       </Col>
                       <Col md={6}>
                       <div className="footer_unorder_wrap">
                           <ul>
                             <li>PARTNER WITH US</li>
                             <li>TERMS AND CONDITIONS</li>
                             <li>CONTACT</li>
                            

                           </ul>
                           <div className="social_wrapper">
                             <ul>
                               
                             <li><i className="fa fa-facebook" aria-hidden="true"></i></li>
                             <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
                             <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
                             <li><i className="fa fa-youtube" aria-hidden="true"></i></li>
                             </ul>
                           </div>
                         </div>

                       </Col>
                     </Row>
                     
                     </Col>
                 </Row>
                 
               </Col>
              
             </Row>
           </Container>

         </div>

     </section>
    );
  }
}
