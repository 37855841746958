import React, { Component } from "react";
import Header from "../../Dabblefit/Common/Header/Header";
import Footer from "../../Dabblefit/Common/Footer/Footer";
import { Col, Container, Row, Form,Button } from "react-bootstrap";
import "./ProviderSignUp.css";
import { Multiselect } from "multiselect-react-dropdown";
import Config from "../../../Config";
import PasswordStrengthBar from "react-password-strength-bar";
import * as ProviderActions from '../../redux/actions/Provider';
import { connect } from "react-redux";
import SuccessModal from '../../constants/SuccessModal/SuccessModal';


 class ProviderSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
    
      studio_name: "",
      email_id: "",
      mobile_number1: "",
      password: "",
      retype_password: "",
      retype_password_error:'',
      errors: {},
      type:'password',
      type1:'password',
      activity_ids:"",
      activity_names:"",
      selected_list1:[],
      branch_location: "",
      branch_location_list:[],
      modal_show:false
 
    };
  }
  componentDidMount() {
    this.props.dispatch(ProviderActions.getActivitityList());
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onHandleTelephoneChange = (e) => {
    let mobile_number = e.target.value;
    mobile_number = Config.mobile_validation(mobile_number);
    this.setState({ [e.target.name]: mobile_number });
  };

  handletoemail = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.mail_validation(event.target.value);
    this.setState({ errors: errors });
  };

  password_toogle_eye = () => this.setState(({type}) => ({type: type === 'text' ? 'password' : 'text'}));
  password_toogle_eye1 = () => this.setState(({type1}) => ({type1: type1 === 'text' ? 'password' : 'text'}));

  handleto_retype_password=(event)=>
  {
    const {password}=this.state;
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.password_validation(password,event.target.value);
    this.setState({ retype_password_error: errors });
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({ selected_list1: selectedList });
    this.setState({
      activity_ids: [...this.state.activity_ids, selectedItem.key],
      activity_names: [...this.state.activity_names, selectedItem.value],
    });
  }
  onRemove = (selectedList, removedItem) => {
    this.setState({ selected_list1: selectedList });
    let array = this.state.activity_ids;
    let array1 = this.state.activity_names;

    const index = array.indexOf(removedItem.key);
    if (index > -1) {
      array.splice(index, 1);
      array1.splice(index, 1);

    }
    this.setState({ activity_ids: array ,activity_names: array1});
  }

  
  add_branch_location_function = () => {
    var { branch_location,activity_ids,activity_names, branch_location_list } = this.state;
    var new_object={
      branch_location: branch_location,
      activity_ids:activity_ids.toString(),
      activity_names:activity_names.toString()
    };
    branch_location_list.push(new_object);
    this.setState({ branch_location_list: branch_location_list });
    console.log(branch_location_list)
    this.setState({branch_location:'',selected_list1:[],activity_ids:'',activity_names:''})

  };

  remove_branch_location_function = (index) => {
    const { branch_location_list } = this.state;
    branch_location_list.splice(index, 1);
    this.setState({ branch_location_list: branch_location_list })
  }



  add_submit_function =(e)=>
  { 
    e.preventDefault();
    const Authorization = Config.getRequestToken();
    const {studio_name,email_id,mobile_number1,password,branch_location_list} =this.state;
    const form_Data1=JSON.stringify({ Authorization,studio_name,email_id,mobile_number1,password});
    const formData = new FormData();
    formData.append('branch_location_list', JSON.stringify({ branch_location_list }));
    formData.append('json', form_Data1);
   
    this.props.dispatch(ProviderActions.providerSignupAction(formData));

  
  }
  componentDidUpdate = () => {
    if (this.props.success_message === "SUCCESS3") {
     this.setState({modal_show:true})
      this.props.dispatch(ProviderActions.empty_message());
    } 
  };

 

//   modal_submit =()=>
//   {
// this.setState({modal_show:true})
//   }
//   handleClose =()=>
//   {
// this.setState({modal_show:false})
//   }





  render() {
    const { password,retype_password_error,branch_location_list } = this.state;
    return (
      <div className="provider_signup_section" id="provider_signup_section">
        <Header />
<SuccessModal subtitle="Your process is ongoing once verified we will contact" title="Registration Is Done" modal_show={this.state.modal_show} handleClose={this.handleClose} modal_backdrop="static" modal_keyboard= {false} location_navigate="/"/>

      
        <Form onSubmit={this.add_submit_function}>
        <section
          className="provider_sign_up_wrapper"
          id="provider_sign_up_wrapper"
        >
          <Container>
            <Row>

          
             
              <Col md={12} className="text-center">
                <div className="get_int_title">
                  <h6>Sign Up</h6>
                </div>
                <div>
                  <Row>
                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Studio Name"
                            name="studio_name"
                            onChange={this.handleChange}
                          />
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-home" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Mail Id"
                            name="email_id"
                            value={this.state.email_id}
                            onChange={this.handletoemail}
                          />
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Form.Group>
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      </div>
                    </Col>

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Mobile Number"
                            name="mobile_number1"
                            placeholder="Mobile Number"
                            value={this.state.mobile_number1}
                            onChange={this.onHandleTelephoneChange}
                            maxLength={10}
                            minLength={10}
                            required
                          />
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-phone" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type={this.state.type}
                            placeholder="Password"
                            name="password"
                            onChange={this.handleChange}
                          />
                           <span className="password__show" onClick={this.password_toogle_eye}>
                                        <i className={this.state.type === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                    </span>
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                        {password && (
                          <PasswordStrengthBar password={password} />
                        )}
                      </div>
                    </Col>
                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                             type={this.state.type1}
                            placeholder="Retype Password"
                            name="retype_password"
                            onChange={this.handleto_retype_password}
                          />
                          <span className="password__show" onClick={this.password_toogle_eye1}>
                                        <i className={this.state.type1 === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                    </span>
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                        <div className="text-danger">
                          {retype_password_error}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="provider_signup_addressdetails"
          id="provider_signup_addressdetails"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="get_int_title">
                  <h6>Location</h6>
                </div>

                <Row>
                  <Col
                    md={{ span: 8, offset: 2 }}
                    sm={12}
                    className="provider_signup_form_control"
                  >
                    {/* <Row>
                      <Col md={12}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Search Here"
                            />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col md={6}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Building Address"
                            />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-home" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Street Address"
                            />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-address-book" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control type="text" placeholder="City" />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-building" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control type="text" placeholder="State" />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-home" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control type="text" placeholder="Pincode" />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i className="fa fa-location-arrow" aria-hidden="true"></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row> */}

                    <Row>
                      <Col md={4}>
                        <div className="contact_dashboard_form_wrapper">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Branch Location"
                              name="branch_location"
                              onChange={this.handleChange}
                              value={this.state.branch_location}
                            />
                            <div className="contact_dashboard_form_icons provider_icons_color">
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>

                      <Col
                        md={6}
                        className="provider_signup_dropdown_wrapper_main"
                      >
                        <div className="provider_signup_dropdown_wrapper">
                          <Multiselect
                            options={this.props.gym_activity_data}
                            selectedValues={this.state.selected_list1}
                            onSelect={this.onSelect}
                            onRemove={this.onRemove}
                            displayValue="value"
                            showCheckbox={true}
                            placeholder="Activities"
                          />
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={12}
                        className="proivder_signup_add_button"
                      >
                        <div onClick={this.add_branch_location_function}>
                          <p >Add</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="provider_signup_card_section"
          id="provider_signup_card_section"
        >
          <Container>
            <Row>
              <Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                {
                 branch_location_list.map((item,index)=>{
                    return(
                      <div  key={index} className="provider_signup_card">
                      <Row >
                        <Col md={12}>
                          
                          <div className="provider_signup_card_content">
                            <Row>
                              <Col md={4}>
                              <h6>
                              Branch : <span>{item.branch_location}</span>
                            </h6>
                              </Col>
                              <Col md={4}>
                              <h6>
                              Activities : <span>{item.activity_names.toString()}</span>
                            </h6>

                              </Col>
                              <Col md={4}>
                            
                              <div className="provider_signup_close_button" onClick={()=>{this.remove_branch_location_function(index)}}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                              </Col>
                            </Row>
                           
                        
                           
                          </div>
                        </Col>
                      </Row>
                    </div>
                    )
                  })
                }
               

            
              </Col>
            </Row>

            <Row>
              <Col md={12}>

                <div className="provider_signup_submit_wrapper">
                <Button type="submit" variant="danger" disabled={branch_location_list.length !==0 ? false : true}>Submit</Button> 
                {/* <Button type="button" variant="danger" onClick={this.modal_submit} >Submit</Button>  */}

                </div>
              
              </Col>
            </Row>
          </Container>
        </section>
        </Form>
        <Footer />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    gym_activity_data: state.ProviderReducer.gym_activity_data,
    success_message: state.ProviderReducer.success_message,

  
  };
};

export default connect(mapStateToProps, null)(ProviderSignUp);