import React, { Component } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "./Register.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import images from "../../constants/images";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      r: 0,
      activeClass:''
    };
  }


  render() {
    return (
      <div className="register_section" id="register_section">
        <Header />

        <section
          className="register_banner_section"
          id="register_banner_section"
        >
          <div className="register_banner_section_overlay"></div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="register_banner_content">
                  <h1>
                    GROW YOUR STUDIO WITH <span>DABBLEFIT</span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

     
    
 <section
          className="register_offers_section"
          id="register_offers_section"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="register_offer_wrapper">
                  <Row>
                    <Col md={4}>
                      <div className="register_offer_container">
                        <div className="register_offer_images">
                          <img
                            src={images.register_offer_1}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="register_offer_title">
                          <h6>DIGITALIZE YOUR BUSINESS</h6>
                        </div>

                        <div className="register_offer_description">
                          <p>
                            Solidify your online presence with the help of our
                            digital platform
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="register_offer_container">
                        <div className="register_offer_images">
                          <img
                            src={images.register_offer_2}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="register_offer_title">
                          <h6>EXPAND YOUR CLIENTELE</h6>
                        </div>

                        <div className="register_offer_description">
                          <p>
                          Increase your brand visibility and cater to a larger audience of fitness enthusiasts
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="register_offer_container register_offer_container1">
                        <div className="register_offer_images">
                          <img
                            src={images.register_offer_3}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                        </div>
                        <div className="register_offer_title">
                          <h6>EARN MORE REVENUE</h6>
                        </div>

                        <div className="register_offer_description">
                          <p>
                           List your available slots and services and get paid for every reservation you get
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
{/* 
        <section
          className="register_offers_section"
          id="register_offers_section"
        >
          <Container>
            <Row>

              <Col md={12} className="text-center">
                <div className="how_it_bg">
                <img src={images.register_how_bg} className="img-fluid" alt="dabblefit" />

                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

<section
          className="mobile_section common_section_top"
          id="mobile_section"
        >
          <Container>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_content">
                  <h1>
                    how it <span>works</span>
                  </h1>
                  <div className="mobile_description"></div>
                </div>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_service">
                  <img
                    src={images.mobile_service}
                    alt="Dabblefit"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <div className="mobile_button_container">
                  <div className="banner_button">
                    <a href="/">Get Started</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <div className="mobile_right_container">
            <img
              src={images.mobile_right}
              alt="Dabblefit"
              className="img-fluid"
            />
          </div> */}
        </section>


        <section className="get_in_touch_section" id="get_in_touch_section">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="get_int_title">
                            <h6>GET IN TOUCH WITH US</h6>
                        </div>
                    </Col>

               
                        <Col  md={{ span: 6, offset: 3 }}>
                         

                            <div className="get_in_form_wrapper">
                            <Form>


                            <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="text" placeholder="Facility Name" />
    <div className="sign_form_icons">
    <i class="fa fa-user" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>

 
  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="text" placeholder="Facility Name" />
    <div className="sign_form_icons">
    <i class="fa fa-envelope-o" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>

  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="text" placeholder="Facility Name" />
    <div className="sign_form_icons">
    <i class="fa fa-phone" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>
 

  <div className="get_in_register get_in_register_top">
      <p>Register</p>
  </div>
 


</Form>
                            </div>

                        </Col>
                  
                </Row>
            </Container>
        </section>

        <Footer />
      </div>
    );
  }
}
