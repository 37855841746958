import React, { Component } from "react";
import Header from "../../Dabblefit/Common/Header/Header";
import Footer from "../../Dabblefit/Common/Footer/Footer";
import { Col, Container, Row, Form,Button } from "react-bootstrap";
import "./ChangePassword.css";
import * as ProviderActions from '../../redux/actions/Provider';
import { connect } from "react-redux";
// import SuccessModal from '../../constants/SuccessModal/SuccessModal';
import Config from '../../../Config';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

 class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      type:'password',
      type1:'password',
      modal_show:false,
      mobile_number:'',
      email_id:'',
      errors: {},
      current_password: '',
      new_password: '',
      confirm_password: '',
      client_ip: '',
      customer_id: '',
      redirect: false,
      isLoadingComplete: true,
      retype_password_error:'',
 
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let params = this.props.match.params.hps;
    let customer_id = params.split("&cp=")[1];
    this.MailVerificationFunction(customer_id);
}



 MailVerificationFunction = (customer_id) => {
    const status = 1;
    const form_Data1 = JSON.stringify({ status, customer_id });
    this.props.dispatch(ProviderActions.MailVerficationAction(form_Data1));
};

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };



  password_toogle_eye = () => this.setState(({type}) => ({type: type === 'text' ? 'password' : 'text'}));
  password_toogle_eye1 = () => this.setState(({type1}) => ({type1: type1 === 'text' ? 'password' : 'text'}));


  componentDidUpdate = () => {
    if (this.props.success_message === "CHANGE_PASSWORD_SUCCESS") {
        this.setState({ redirect: true });
        this.setState({current_password:'',new_password:'',confirm_password:''});
        toast.success("Successfully updated the Login details..");
        this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "CHANGE_PASSWORD_ERROR") {
        toast.error("Can't Perform current action Now, Please Try again later..");
        this.setState({current_password:'',new_password:'',confirm_password:''});
         this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "MAIL_VERIFICATION_SUCCESS") {
        toast.success("Change Your Password details..");
        this.setState({current_password:'',new_password:'',confirm_password:''});
        this.setState({customer_id:this.props.customer_id });
         this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "MAIL_VERIFICATION_ERROR") {
        toast.error(this.props.error_message);
        this.setState({current_password:'',new_password:'',confirm_password:''});
        this.setState({ redirect: true });
         this.props.dispatch(ProviderActions.empty_message());
    }else if (this.props.success_message === "MAIL_VERIFICATION_CATCH_ERROR") {
        toast.error(this.props.error_message);
        this.setState({current_password:'',new_password:'',confirm_password:''});
        this.setState({ redirect: true });
         this.props.dispatch(ProviderActions.empty_message());
    }
    // else if (this.props.success_message === "CHANGE_PASSWORD_SUCCESS") {
    //     toast.success("Successfully Password Changed");
    //     this.setState({current_password:'',new_password:'',confirm_password:''});
    //     this.setState({ redirect: true });
    //      this.props.dispatch(ProviderActions.empty_message());
    // }
    // else if (this.props.success_message === "CHANGE_PASSWORD_ERROR") {
    //     toast.error(this.props.error_message);
    //     this.setState({current_password:'',new_password:'',confirm_password:''});
    //     this.setState({ redirect: true });
    //      this.props.dispatch(ProviderActions.empty_message());
    // }
  };

  
  handleto_retype_password=(event)=>
  {
    const {new_password}=this.state;
    const { name, value } = event.target;
    this.setState({ [name]: value });
    let errors = Config.password_validation(new_password,event.target.value);
    this.setState({ retype_password_error: errors });
  }
 


  handleSubmit = (e) =>{
    e.preventDefault();
 
    const { current_password, new_password, confirm_password, customer_id } = this.state;
    if(new_password === current_password){
        toast.success("New and Current Passwords same characters");
    }else if(!(new_password === confirm_password)){
        toast.error("Enter correct Confirm password");
    }else{
        const Authorization = Config.getRequestToken();           
        const form_Data1 = JSON.stringify({ current_password, new_password, confirm_password, Authorization, customer_id });
        this.props.dispatch(ProviderActions.ChangePasswordAction(form_Data1));
    }
}



  render() {
    if (this.state.redirect) {
        return <Redirect to="/" />
    }
  const {retype_password_error} =this.state;
    return (
      <div >
        <Header />
{/* <SuccessModal subtitle="Your process is ongoing once verified we will contact" title="Registration Is Done" modal_show={this.state.modal_show} handleClose={this.handleClose} modal_backdrop="static" modal_keyboard= {false} location_navigate="/"/> */}

      
        <Form onSubmit={this.handleSubmit}>
        <section
          className="provider_sign_in_wrapper"
          id="provider_sign_in_wrapper"
        >
          <Container>
            <Row>

          
             
              <Col md={12} className="text-center">
                <div className="get_int_title">
                  <h6>Change Password</h6>
                </div>
                <div>
                  <Row>
                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                          type="text" 
                          placeholder="Old Password"
                           name="current_password" value={this.state.current_password} onChange={this.handleChange}
                          />
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>

                   

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type={this.state.type}
                            placeholder="New Password" name="new_password" value={this.state.new_password} onChange={this.handleChange} required
                          />
                           <span className="password__show" onClick={this.password_toogle_eye}>
                                        <i className={this.state.type === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                    </span>
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                      
                      </div>
                    </Col>

                    <Col
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 8, offset: 2 }}
                    >
                      <div className="contact_dashboard_form_wrapper">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label></Form.Label>
                          <Form.Control
                             type={this.state.type1}
                            placeholder="Confirm Password"
                            name="confirm_password"
                            value={this.state.confirm_password}
                            onChange={this.handleto_retype_password}
                          />
                          <span className="password__show" onClick={this.password_toogle_eye1}>
                                        <i className={this.state.type1 === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                    </span>
                          <div className="contact_dashboard_form_icons provider_icons_color">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </Form.Group>
                        <div className="text-danger">
                          {retype_password_error}
                        </div>
                      </div>
                    </Col>

                  

                    
                  
                  </Row>
                  <div className="provider_signin_buttton_wrapper">
                <Button type="submit" variant="danger">Reset</Button> 
          
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>


 
        </Form>

        
        <Footer />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    gym_activity_data: state.ProviderReducer.gym_activity_data,
    success_message: state.ProviderReducer.success_message,
    error_message: state.ProviderReducer.error_message,
    customer_id: state.ProviderReducer.customer_id,


  };
};

export default connect(mapStateToProps, null)(ChangePassword);