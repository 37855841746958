import React, { Component } from 'react';
import {Modal,Form,} from 'react-bootstrap';
import './SignUpModal.css';

export default class SignUpModal extends Component {
  render() {
    return (
      
      <div>
        
      <Modal  centered show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header >
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <div className="get_in_form_wrapper">
        <p className="modal_create_para">CREATE A NEW ACCOUNT</p>

                            <Form>

  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="text" placeholder="Facility Name" />
    <div className="sign_form_icons">
    <i class="fa fa-user" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>



  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="email"  placeholder="Email" />
    <div className="sign_form_icons">
    <i class="fa fa-envelope-o" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>

  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="password"  placeholder="Password" />
    <div className="sign_form_icons">
    <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>
  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="password"  placeholder="Confirm Password" />
    <div className="sign_form_icons">
    <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>
  <div className="sign_form_wrapper">
  <Form.Group controlId="formBasicPassword">
    <Form.Label></Form.Label>
    <Form.Control type="password"  placeholder="Phone Number" />
    <div className="sign_form_icons">
    <i class="fa fa-phone" aria-hidden="true"></i>
    </div>
  </Form.Group>
  </div>

  <div className="sign_form_check_wrapper">
  <Form.Group controlId="formBasicCheckbox">
   <div>
   <Form.Check type="checkbox" label="Remember Me" />
   </div>
  </Form.Group>
  <Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="Already signed up ?" />
  </Form.Group>
  </div>

</Form>
                            </div>


            
            
            
          </Modal.Body>
        <Modal.Footer>
        <div className="get_in_register">
      <p>Register</p>
  </div>
      
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
