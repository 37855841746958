import React, { Component } from "react";
import { Row, Col,Form } from "react-bootstrap";
import { Line, Bar, Pie } from "react-chartjs-2";
import './Home.css';
const Revenue_chart = {
  labels: ["01", "02", "03", "04", "05", "06", "07"],
  datasets: [
    {
      label: "Rainfall",
      fill: false,
      lineTension: 0.5,

      backgroundColor: "black",
      borderColor: "red",
      borderWidth: 1,
      data: [10, 20, 22, 26, 30],
    },
    {
      label: "data",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "black",
      borderColor: "red",
      borderWidth: 2,
      data: [10, 20, 22, 26, 30],
    },
  ],
};

const Bar_chart = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Rainfall",
      backgroundColor: "red",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 65, 59, 80, 81, 56],
    },
  ],
};

const Pie_chart = {
  labels: ["Attened", "Missed", "Cancelled"],
  datasets: [
    {
      label: "Rainfall",
      backgroundColor: ["#242424", "#e22024", "#141414"],
      hoverBackgroundColor: ["#242424", "#e22024", "#141414"],
      data: [30, 30, 40],
    },
  ],
};
const Pie_chart_rating = {
  labels: ["5 Stars", "4 Stars", "3 Stars", "2 Stars", "1 Stars"],
  datasets: [
    {
      label: "Rainfall",
      backgroundColor: ["#242424", "#e22024", "#141414", "green", "red"],
      hoverBackgroundColor: ["#242424", "#e22024", "#141414"],
      data: [30, 20, 10, 20, 20],
    },
  ],
};

export default class Home extends Component {

  componentDidMount()
  {
    window.scrollTo(0,0)
  }
  render() {
    return (
      <section>
      
          <Row>
            <Col md={12}>
      
               
                  <Row>
                    <Col md={2}>
                      <div className="home_dasbord_period_dropdown">
                        <h6>Home</h6>

                        <div className="home_dashbord_dropdown_wrapper">
                          <Form.Control
                            className="dasboard_section_dropdown input-lg"
                            as="select"
                          >
                            <option value="Period" selected disabled>
                              Period
                            </option>

                            <option value="Current Month">Current Month</option>
                            <option value="Last 3 Month">Last 3 Month</option>
                            <option value="Last 6 Month">Last 6 Month</option>
                            <option value="Last 1 Year">Last 1 Year</option>
                          </Form.Control>
                          <i
                            class="fa fa-caret-down period_icons "
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                    <h6 className="home_card_subtitle">Total Revenue : <span  className="home_card_highlight">15000</span></h6>
                    </Col>
                    <Col md={12} lg={12} xl={8} className="Revenue_card_dasborad">

                     
                      <div>
                        <Line
                          data={Revenue_chart}
                          height="100"
                          options={{
                            title: {
                              display: true,
                              text: "Revenue",
                              fontSize: 20,
                              fontColor: "#e22024",
                            },
                            legend: {
                              display: true,
                              position: "right",
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                  <Col md={12}>
                    <h6 className="home_card_subtitle">Total Reservation : <span className="home_card_highlight">150</span></h6>
                    </Col>
                    <Col md={12} lg={8}  className="Revenue_card_dasborad">
                      <div>
                        <Bar
                          data={Bar_chart}
                          height="100"
                          options={{
                            title: {
                              display: true,
                              text: "Reservation",
                              fontSize: 20,
                              fontColor: "#e22024",
                            },
                            legend: {
                              display: true,
                              position: "right",
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} lg={12} xl={8}>
                      <Row>
                        <Col md={6}>
                          <div className="Revenue_card_dasborad">
                            <Pie
                              data={Pie_chart}
                              options={{
                                title: {
                                  display: true,
                                  text: "Reservations Breakdown",
                                  fontSize: 20,
                                  fontColor: "#e22024",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="Revenue_card_dasborad">
                            <Pie
                              data={Pie_chart_rating}
                              options={{
                                title: {
                                  display: true,
                                  text: "Rating Breakdown",
                                  fontSize: 20,
                                  fontColor: "#e22024",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
               
             
            </Col>
          </Row>
        
      </section>
    );
  }
}
