import React from 'react';
import './HeadingTitle.css'

const HeadingTitle = (props) => {
    return (
        <div>
                <div className="sub-text-wrapper">
                    <span className="sub-text">{props.heading}</span>
                    <h2 class="title">
                      <span class="watermark"></span>{props.title}
                    </h2>
                    <div className="description">
                    <p>
                        {props.description}
                       </p>
                  </div>
                   
                  
                  </div>
        </div>
    );
};

export default HeadingTitle;