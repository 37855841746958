import React, { Component } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  NavDropdown
} from "react-bootstrap";
import "./UserProfile.css";

import { GALLERY_DATA } from "../../constants/AccountData";
import images from "../../constants/images";

import Header from "../../Dabblefit/Common/Header/Header";
import Footer from "../../Dabblefit/Common/Footer/Footer";

export default class UserProfile extends Component {
  render() {
    return (
      <div>

<Header />
     
    
   <section
          className="user_profile_icons_section"
          id="user_profile_icons_section"
        >
          <Container fluid>
            <Row>
              <Col md={12}>
            <div className="user_profile_icons_container">
            <img
              src={images.user_login_icon}
              alt="Dabblefit"
              className="img-fluid"
            />
                  <NavDropdown title="Ajith Kumar" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Logout</NavDropdown.Item>
  
      </NavDropdown>
            </div>
      
              </Col>
            </Row>
          </Container>
   
        </section>


        <section
          className="userprofile_count_section "
          id="userprofile_count_section"
        >
          <Container>
            <Row>
              <Col md={12}>
                <div className="userprofile_count_content">
                  <div className="userprofile_count_number">
                    <span>15</span>
                  </div>

                  <div>
                    <p className="userprofile_subtitle">Dabble This Month</p>{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="user_profile_search_wrapper user_profile_common_top">
          <Container>
            <Row className="userprofile_search_wrapper">
              <Col md={6} className="userprofile_search_inner">
                <div className="userprofile_search_container">
                  <div>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        className="search_text"
                        type="text"
                        placeholder="Enter Here"
                      />
                    </Form.Group>
                  </div>

                  <div className="search_button">
                    <p>Search</p>
                  </div>
                  <div></div>
                </div>
              </Col>

              <Col md={2}>
                <div className="user_profile_dropdown_wrapper">
                  <Form.Control
                    as="select"
                    className="user_profile_dropdown_container user_profile_dropdown_inner"
                    defaultValue="AREA" 
                  >
                    <option value="AREA" disabled>AREA</option>
                    <option>ADYAR</option>
                    <option>ANNA NAGAR</option>
                    <option>NUNGABAKKAM</option>
                    <option>T NAGAR</option>
                  </Form.Control>
                  <i
                    className="fa fa-caret-down user_profile_dropdown_inner_icons "
                    aria-hidden="true"
                  ></i>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="user_current_booking_section user_profile_common_top">
          <Container>
            <Row>
              <Col md={12}>
                <div className="user_current_title">
                  <h4>
                    Current <span>Booking</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <Row>
           
                   <Col md={12} xs={12} lg={12} xl={{ span: 6, offset: 3 }}>
                <div className="user_current_cards_details">
                  <Row>
                    <Col md={12}>
                      <Row >
                        <div className="user_current_description">
                          <h6>Name  : <span>Aarron</span></h6>
                          <h6>Venue : <span>Chennai</span></h6>
                          <h6>Time  : <span> 2.00</span></h6>
                        </div>
                      </Row>

                      <Row className="user_current_content"> 
                        <div className="d-flex">
                          <p>Book</p>
                          <p>Cancel</p>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="user_gallery_section user_profile_common_top">
          <Container>
          <Row>
              <Col md={12}>
                <div className="gallery_title">
                  <h4>
                    what would you like to <span>dabble with today</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <div className="gallery_top">
              <Row>
                {(GALLERY_DATA || []).map((x, index) => {
                  return (
                    <>
                      <Col md={4} xs={6} lg={4}>
                        <div className="gallery_card">
                          <img
                            src={x.gallery_image}
                            alt="dabblefit"
                            className="img-fluid"
                          />
                          <div>
                            <p>{x.name}</p>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row>
                <Col md={12}>
                  <div className="gallery_view">
                    <p>Explore Studios</p>
                    <div className="view_triangle"></div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="user_current_booking_section user_profile_common_top">
          <Container>
            <Row>
              <Col md={12}>
                <div className="user_current_title">
                  <h4>
                    Recent<span>Activity</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} xs={12} lg={12} xl={{ span: 6, offset: 3 }}>
                <div className="user_current_cards_details">
                  <Row>
                    <Col md={12}>
                      <Row >
                        <div className="user_current_description">
                          <h6>Name  : <span>Aarron</span></h6>
                          <h6>Venue : <span>Chennai</span></h6>
                          <h6>Time  : <span> 2.00</span></h6>
                        </div>
                      </Row>

                      <Row className="user_current_content"> 
                        <div className="d-flex">
                          <p>Book</p>
                       
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="user_current_booking_section user_profile_common_top">
          <Container>
            <Row>
              <Col md={12}>
                <div className="user_current_title">
                  <h4>
                    Suggested<span></span>
                  </h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col  md={12} xs={12} lg={12} xl={{ span: 6, offset: 3 }}>
                <div className="user_current_cards_details">
                  <Row>
                    <Col md={12}>
                      <Row >
                        <div className="user_current_description">
                          <h6>Name  : <span>Aarron</span></h6>
                          <h6>Venue : <span>Chennai</span></h6>
                          <h6>Time  : <span> 2.00</span></h6>
                        </div>
                      </Row>

                      <Row className="user_current_content"> 
                        <div className="d-flex">
                          <p>Book</p>
                       
                        </div>
                      </Row>
                    </Col>
                  </Row>

                  
                </div>
                <div className="user_current_cards_details">
                  <Row>
                    <Col md={12}>
                      <Row >
                        <div className="user_current_description">
                          <h6>Name  : <span>Aarron</span></h6>
                          <h6>Venue : <span>Chennai</span></h6>
                          <h6>Time  : <span> 2.00</span></h6>
                        </div>
                      </Row>

                      <Row className="user_current_content"> 
                        <div className="d-flex">
                          <p>Book</p>
                       
                        </div>
                      </Row>
                    </Col>
                  </Row>

                  
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    <Footer />
      </div>
  
    );
  }
}
