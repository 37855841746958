import React from 'react';
import './PricePlan.css';
import GetStartedButton from "../../constant/ButtonBlue/GetstartedButton";
import {Row,Col,Container} from 'react-bootstrap';


const Priceplan = (props) => {
    return (

        <section className="our-price-section" id="our-price-section">
        <Container>
        <Row>
          <Col md={12}>
            <div className="about-intro-content text-center">
              <span className="about-subtext">PRICING</span>
              <h3 className="about-subtitle">Our Pricing Plan</h3>
            </div>
          </Col>
        </Row>

        <div className="our-price-card-padding">
        <Row>
          {
           props.priceplan.map((our_plan,our_id)=>
           {
             return(
            <Col md={4} key={our_id}>
             
        <div className={`our-price-card ${our_plan.activeClass}`}>
        <div className="our-price-image-card">
          <img src={our_plan.image_url} className="img-fluid" />
        </div>
        <div className="our-price-image-inner">
          <p>{our_plan.title}</p>
        </div>
        <div className="our-price-amount-wrap">
          <span className="our-price-amount-symbol">&#8377;</span>
          <span className="our-price-amount">1600</span>
          <span className="our-price-text">Monthly Package</span>
        </div>
        <div className="our-price-list">
          <div className="our-price-list-wrap">
            <p>our-price-list</p>
            <p><i class="fa fa-check" aria-hidden="true"></i></p>
          </div>
          <div className="our-price-list-wrap">
            <p>1 Native Android App</p>
            <p><i class="fa fa-check" aria-hidden="true"></i></p>
          </div>
          
          <div className="our-price-list-wrap">
            <p>Multi-Language Support</p>
            <p><i class="fa fa-close" aria-hidden="true"></i></p>
          </div>
          
          <div className="our-price-list-wrap">
            <p>Support via E-mail and Phone</p>
            <p><i class="fa fa-check" aria-hidden="true"></i></p>
          </div>

        </div>

       
        
    </div>
    <div className="our-price-buynow">
        <GetStartedButton title="BUY NOW" />
        </div>
        
            </Col>
               )
              })
             }
        </Row>
        </div>
        </Container>

    </section>
        

    );
};

export default Priceplan;