import Config from "../../../Config";
import http from "../../../http_common";
export const GYM_ACTIVITIES = "GYM_ACTIVITIES";

export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const IS_LOADING = "IS_LOADING";
export const CUSTOMER_ID = "CUSTOMER_ID";
export const STUDIO_DATA = "STUDIO_DATA";
export const CUSTOMER_DATA = "CUSTOMER_DATA";
export const STUDIO_OBJECT = "STUDIO_OBJECT";
export const STUDIO_INDEX_OBJECT = "STUDIO_INDEX_OBJECT";
export const STUDIO_LIST_UPDATE = "STUDIO_LIST_UPDATE";
export const IMAGE_ADDRESS_LIST = "IMAGE_ADDRESS_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";

export const SCHEDULE_LIST = "SCHEDULE_LIST";



export const get_studio_index_object = (studio_index_value) => {
  return function (dispatch) {
    dispatch({ type: "STUDIO_INDEX_OBJECT", studio_index: studio_index_value });
  };
};


export const get_studio_object = (id) => {
  return function (dispatch) {
    dispatch({ type: "STUDIO_OBJECT", studio_object_id: id });
    dispatch({ type: "SUCCESS_MESSAGE", success_title: "STUDIO_OBJECT_CHANGE_SUCCESS" });
  };
};

export const empty_message = () => {
  return function (dispatch) {
    dispatch({ type: "SUCCESS_MESSAGE", success_title: "" });

  };
};


export const getActivitityList = () => {
  return async (dispatch) => {
    const Authorization = Config.getRequestToken();
    const company_id = "1";
    const branch_id = "0";
    const role = "ADMIN";
    const item_category_type = "GA";

    const form_Data1 = JSON.stringify({
      Authorization,
      company_id,
      branch_id,
      role,
      item_category_type,
    });
    let response = await fetch(Config.BaseUrl + "SelectBoxItemCategoryList/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    });
    let responseJsonData = await response.json();
    dispatch({
      type: "GYM_ACTIVITIES",
      gym_activity: responseJsonData,
    });
  };
};

export const providerSignupAction = (formData) => {
  return async (dispatch) => {
    http
      .post("/ProviderSignUp", formData, {
        headers: {},
      })
      .then((responseJson) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        if (responseJson.data.message === "SUCCESS") {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "SUCCESS3" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};

export const SignInAction = (username, password, client_ip) => {
  return async (dispatch) => {
    const request_token = Config.getRequestToken();
    const mutation = `mutation SignInAction($username:String, $password:String, $client_ip:String, $request_token:String) {
              SignInAction(username:$username, password:$password, client_ip:$client_ip, request_token:$request_token){
            message,first_name,last_name,token,image_address,mobile_number1,email_id,login_history_id
        }
    }`;
    fetch(Config.BaseUrl + "graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: mutation,
        variables: { username, password, client_ip, request_token },
      }),
    })
      .then((response) => response.json())
      .then((responseText) => {

      


        if (responseText.data.SignInAction["message"] === "SUCCESS") {
          localStorage.setItem(
            "Authorization",
            responseText.data.SignInAction["token"]
          );
          localStorage.setItem(
            "login_history_id",
            responseText.data.SignInAction["login_history_id"]
          );
         
       
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "SIGN_IN_SUCCESS",
          });
         
         
        } else {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "SIGN_IN_ERROR" });
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseText.data.SignInAction["message"],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "SUCCESS_MESSAGE",
          success_title: "SIGN_IN_CATCH_ERROR",
        });
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};

export const PasswordResetAction = (form_Data1) => {
  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    };
    fetch(Config.BaseUrl + "GetResetPasswordLink/", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingComplete: false });
        if (json["message"] === "SUCCESS") {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "RESET_SUCCESS" });
        } else {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "RESET_ERROR" });
          dispatch({ type: "ERROR_MESSAGE", error_title: json["message"] });
        }
      })
      .catch((error1) => {
        dispatch({
          type: "SUCCESS_MESSAGE",
          success_title: "RESET_CATCH_ERROR",
        });
        dispatch({
          type: "ERROR_MESSAGE",
          error_title: error1,
        });
      });
  };
};

export const ChangePasswordAction = (form_Data1) => {
  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    };
    fetch(Config.BaseUrl + "ChangePasswordFunction/", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json["message"] === "SUCCESS") {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CHANGE_PASSWORD_SUCCESS",
          });
        } else {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CHANGE_PASSWORD_ERROR",
          });
          dispatch({ type: "ERROR_MESSAGE", error_title: json["message"] });
        }
      });
  };
};



export const changeNewPasswordAction = (form_Data1) => {
  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    };
    fetch(Config.BaseUrl + "ChangePassword/", requestOptions)
      .then((res) => res.json())
      .then((json) => {
      
        if (json["message"] === "SUCCESS") {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CHANGE_PASSWORD_SUCCESS",
          });
        } else {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CHANGE_PASSWORD_ERROR",
          });
          dispatch({ type: "ERROR_MESSAGE", error_title: json["message"] });
        }
      });
  };
};

export const logoutAction = (form_Data1) => {

  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    };
    fetch(Config.BaseUrl + "LogoutAction/", requestOptions)
      .then((res) => res.json())
      .then((json) => {
      
        if (json["message"] === "SUCCESS") {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "LOGOUT_SUCCESS",
          });
        } else {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "LOGOUT_SUCCESS",
          });
          dispatch({ type: "ERROR_MESSAGE", error_title: json["message"] });
        }
      });
  };
};







export const contactUpdatePointAction = (form_Data1) => {
 
  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    };
    fetch(Config.BaseUrl + "UpdatePointOfContact/", requestOptions)
      .then((res) => res.json())
      .then((json) => {
      
        if (json["message"] === "SUCCESS") {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CONTACT_UPDATE_SUCCESS",
          });

         
        } else {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "CONTACT_UPDATE_ERROR",
          });
          dispatch({ type: "ERROR_MESSAGE", error_title: json["message"] });
        }
      });
  };
};


export const get_studio_list_update = (studio_updated_object) => {
  return function (dispatch) {
    dispatch({ type: "STUDIO_LIST_UPDATE", studio_update_object: studio_updated_object });


  };
};



export const MailVerficationAction = (form_Data1) => {
  return async (dispatch) => {
    fetch(Config.BaseUrl + "MailVerification", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "SUCCESS") {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "MAIL_VERIFICATION_SUCCESS",
          });
          dispatch({
            type: "CUSTOMER_ID",
            customer_verfication_id: responseJson.id,
          });
        } else {
          dispatch({
            type: "SUCCESS_MESSAGE",
            success_title: "MAIL_VERIFICATION_ERROR",
          });
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.message,
          });
        }
      })
      .catch((error1) => {
        dispatch({
          type: "SUCCESS_MESSAGE",
          success_title: "MAIL_VERIFICATION_CATCH_ERROR",
        });
        dispatch({ type: "ERROR_MESSAGE", error_title: error1 });
      });
  };
};

export const GetStudioData = () => {
  return async (dispatch) => {
    const Authorization = localStorage.getItem("Authorization");
 
    const customer_id = "";
    const form_Data1 = JSON.stringify({ Authorization, customer_id });
    let response = await fetch(Config.BaseUrl + "GetStudioData/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    });
    let responseJsonData = await response.json();

    console.log(responseJsonData)

    dispatch({
      type: "STUDIO_DATA",
      studio_data_list: responseJsonData["studioLocation"],
    });
    dispatch({
      type: "CUSTOMER_DATA",
      customer_object: responseJsonData["customer"],
    });
    dispatch({
      type: "IMAGE_ADDRESS_LIST",
      img_address_list: responseJsonData["imageAddressList"],
    });

    dispatch({
      type: "SCHEDULE_LIST",
      schedule_list: responseJsonData["sessionSchedule"],
    });
    

  };
};





export const ProfileImageChange = (formData) => {
  return async (dispatch) => {
    http
      .post("/CustomerImageUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((responseJson) => {
        if (responseJson.data.message === "SUCCESS") {
          
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "PROFILE_IMAGE_UPLOAD" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};


export const studioCoverImageUpload = (formData) => {
 
  return async (dispatch) => {
    http
      .post("/StudioCoverImageUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((responseJson) => {
        if (responseJson.data.message === "SUCCESS") {
          
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "COVER_IMAGE_UPLOAD" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};


export const studioImageUpload = (formData) => {
  return async (dispatch) => {
    http
      .post("/StudioImageUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((responseJson) => {
        if (responseJson.data.message === "SUCCESS") {
          
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "STUDIO_MULTI_IMAGE_UPLOAD" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};






export const getCategoryList = (category_type) => {
  return async (dispatch) => {
    const Authorization = localStorage.getItem('Authorization');
    const company_id = "1";
    const branch_id = "0"
    const role = "ADMIN";
    const item_category_type = category_type;
    const form_Data1 = JSON.stringify({ Authorization, company_id, branch_id, role, item_category_type });
    let response = await fetch(Config.BaseUrl + "SelectBoxItemCategoryList/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    });
    let responseJsonData = await response.json();
    dispatch({
      type: "CATEGORY_LIST",
      category_list: responseJsonData,
    });
   
  };
};



export const studioDataUpload = (formData) => {
  return async (dispatch) => {
    http
      .post("/StudioDataUpload", formData, {
        headers: {},
      })
      .then((responseJson) => {
        if (responseJson.data.message === "SUCCESS") {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "STUDIO_DATA_SUCCESS" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};





export const AddSessionSchedule = (formData) => {
  return async (dispatch) => {
    http
      .post("/AddSessionSchedule", formData, {
        headers: {},
      })
      .then((responseJson) => {
       
        if (responseJson.data.message === "SUCCESS") {
          dispatch({ type: "SUCCESS_MESSAGE", success_title: "SESSION_SCHEDULE_SUCCESS" });
        } else {
          dispatch({
            type: "ERROR_MESSAGE",
            error_title: responseJson.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "IS_LOADING", is_loading: false });
        dispatch({ type: "ERROR_MESSAGE", error_title: error });
      });
  };
};


export const GetScheduleSession = () => {
  return async (dispatch) => {
    const Authorization = localStorage.getItem("Authorization");
    const customer_id = "";
    const form_Data1 = JSON.stringify({ Authorization, customer_id });
    let response = await fetch(Config.BaseUrl + "GetScheduleSessionData/", {
     
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: form_Data1,
    });
    let responseJsonData = await response.json();
    alert(JSON.stringify(responseJsonData))
    dispatch({
      type: "SCHEDULE_LIST",
      schedule_list: responseJsonData["sessionSchedule"],
    });
  
  };
};



// export const GetScheduleSession = () => {


//   return async (dispatch) => {
//     const Authorization = localStorage.getItem("Authorization");
//     const customer_id = "";
//     const form_Data1 = JSON.stringify({ Authorization, customer_id });
//     let response = await fetch(Config.BaseUrl + "GetScheduleSession/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//       },
//       body: form_Data1,
//     });
//     let responseJsonData = await response.json();

//     alert(response)

//     // alert(JSON.stringify(responseJsonData))

//     dispatch({
//       type: "SCHEDULE_LIST",schedule_list: responseJsonData["sessionSchedule"]});
//   };
// };
