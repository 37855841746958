import React from "react";
import { Modal, Button } from "react-bootstrap";
import {Link} from 'react-router-dom';
import './SuccessModal.css';

const SuccessModal = ({
  handleClose,
  modal_show,
  modal_backdrop,
  modal_keyboard,
  location_navigate,
  title,
  subtitle
}) => {
  return (
    <div className="success_modal_wrapper" id="success_modal_wrapper">
      <Modal
        onHide={handleClose}
        backdrop={modal_backdrop}
        keyboard={modal_keyboard}
        show={modal_show}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="success_modal_title" >{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="success_modal_description">{subtitle}</Modal.Body>
        <Modal.Footer> 
        <Link to={location_navigate}>
        <Button className="success_modal_button" variant="primary" onClick={handleClose}>
            Home
          </Button>
            </Link> 
         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuccessModal;
