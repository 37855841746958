import React from 'react';
import {Navbar,Nav,Form,FormControl,Button} from 'react-bootstrap';
import './Header.css';
import { withRouter } from "react-router";
import images from '../../../constants/images'

const Headerbar = props => {
  const { location } = props;
    return (
 <div>
     <Navbar bg="light"  className="fixed-top" expand="lg">
  <Navbar.Brand href="/">
      <img  src={images.dabble_logo} alt="Dabblefit" className="img-fluid"/>
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav activeKey={location.pathname} className="mr-auto ml-auto">
      {/* <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/OurFacilities">Explore Studios</Nav.Link>
      <Nav.Link href="/Register">Sign Up</Nav.Link>
      <Nav.Link href="/Search" >Search</Nav.Link>
      <Nav.Link href="/OurTrainers">About Us</Nav.Link>
      <Nav.Link href="/ProviderSignIn" >Sign In</Nav.Link>
      <Nav.Link href="/Consumer" >Consumer</Nav.Link> */}

      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/OurFacilities">Explore Studios</Nav.Link>
      <Nav.Link >How it works</Nav.Link>
      <Nav.Link  >Partner with us</Nav.Link>
      <Nav.Link href="/OurTrainers">Blog </Nav.Link>
     


    </Nav>
    <Form inline>
      
      <Button href="/ProviderSignIn" variant="outline-danger">Sign In</Button>
    </Form>
  </Navbar.Collapse>
</Navbar>

  
 </div>
    );
  }


  export default withRouter(Headerbar);

