import images from "./images";







export const Repeat_data = [
  {
    key: "0",
    value: "Mon",
    status: "0",
  },
  {
    key: "1",
    value: "Tue",
    status: "0",
  },
  {
    key: "2",
    value: "Wen",
    status: "0",
  },
  {
    key: "3",
    value: "Thr",
    status: "0",
  },
  {
    key: "4",
    value: "Fri",
    status: "0",
  },
  {
    key: "5",
    value: "Sat",
    status: "0",
  },
  {
    key: "6",
    value: "Sun",
    status: "0",
  },
];


export const Blog_list = [
    {
        id: '1',
        name: "Residential",
        image_url:images.blog_image1,
       
        status:'1'

    },
    {
        id: '2',
        name: "Commercial",
        image_url:images.blog_image2,
        status:'1'


    },
    {
        id: '3',
        name: "Residential",
        image_url:images.blog_image3,
        status:'1'

    },
    {
        id: '4',
        name: "Commercial",
        image_url:images.blog_image4,
        status:'1'


    },

];


export const Date_data = [
    {
        id: '1',
        date_number:'21TH',
        date_month:'FEB',
        name: "COMBAT TRAINING",    
        gallery_image:images.gallery1
    },
    {
        id: '2',
        date_number:'22TH',
        date_month:'FEB',
        name: "HIIT",
        gallery_image:images.gallery2
        
    },
    {
        id: '3',
        date_number:'23TH',
        date_month:'FEB',
        name: "WATER SPORTS",
        gallery_image:images.gallery3
    },
    {
        id: '4',
        name: "GYM",
        date_number:'24TH',
        date_month:'FEB',
        gallery_image:images.gallery4
        
    },  {
        id: '5',
        date_number:'25TH',
        date_month:'FEB',
        name: "OUTDOOR TRAINING",
        gallery_image:images.gallery5
       
    },
    {
        id: '6',
        name: "YOGA",
        date_number:'26TH',
        date_month:'FEB',
        gallery_image:images.gallery6
    
    },
    {
        id: '1',
        date_number:'27TH',
        date_month:'FEB',
        name: "COMBAT TRAINING",
        gallery_image:images.gallery1
    },
    {
        id: '2',
        date_number:'28TH',
        date_month:'FEB',
        name: "HIIT",
        gallery_image:images.gallery2
        
    },
    {
        id: '3',
        date_number:'1ST',
        date_month:'MAR',
        name: "WATER SPORTS",
        gallery_image:images.gallery3
    },
    {
        id: '4',
        name: "GYM",
        date_number:'2ND',
        date_month:'MAR',
        gallery_image:images.gallery4
        
    },  {
        id: '5',
        date_number:'3RD',
        date_month:'MAR',
        name: "OUTDOOR TRAINING",
        gallery_image:images.gallery5
       
    },
    {
        id: '6',
        date_number:'4TH',
        date_month:'MAR',
        name: "YOGA",
        gallery_image:images.gallery6
    
    },

];

export const GALLERY_DATA = [
    {
        id: '1',
        name: "COMBAT TRAINING",
        gallery_image:images.gallery1
    },
    {
        id: '2',
        name: "Gym and Functional fitness",
        gallery_image:images.gallery2
        
    },
    {
        id: '3',
        name: "Dance Fitness",
        gallery_image:images.gallery3
    },
    {
        id: '4',
        name: "Water sports",
        gallery_image:images.gallery4
        
    },  {
        id: '5',
        name: "Yoga and Pilates",
        gallery_image:images.gallery5
       
    },
    {
        id: '6',
        name: "Not your everyday choices",
        gallery_image:images.gallery6
    
    },

];



export const Addional_Rooms_Data = [
    {
        id: '1',
        name: "Pooja Room",
        status:"0"
    },
    {
        id: '2',
        name: "Servant Room",
        status:"0"

    },
    {
        id: '3',
        name: "Study Room",
        status:"0"

    },
    {
        id: '4',
        name: "Extra Room",
        status:"0"

    },

];




export const Consumer_data=[
    {
        id: '1',
        title: "ANY WORKOUT",
        description:'Choose from a diverse list of fitness options available in your city',
        image_url:images.consumer_icon1,
    },
    {
        id: '2',
        title: "ANY TIME AND LOCATION",
        description:"Workout at any of our partnered facilties / studios. At a time and place of your choice",
        image_url:images.consumer_icon2,

    },
    {
        id: '3',
        title: "INDEPENDENT SPECIALISTS",
        description:"Book one-on-one / group sessions with Independent trainers who specialize in various fields",
        image_url:images.consumer_icon3,


    }
  
]




