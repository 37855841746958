import React, { Component } from "react";
import "./About.css";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import PricePlan from "../common/PricePlan/Priceplan";
import *  as AccountsData  from '../constant/AccountData/AccountData';


import { Col, Container, Row } from "react-bootstrap";
import GetStartedButton from "../constant/ButtonBlue/GetstartedButton";
import images from '../constant/Images/images';

export default class About extends Component {
  render() {
    return (
      <section className="about-section-wrap" id="about-section">
        <Header />

        <div className="about-section-intro">
          <Container>
            <Row>
              <Col md={6}>
                <div className="about-intro-image">
                  <img
                    src={images.about_banner}
                    className="img-fluid"
                    alt="vimkes"
                  />
                </div>
                <div className="about-intro-shape">
                  <img
                    src={images.about_dot}
                    className="img-fluid"
                    alt="vimkes"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="about-intro-content-wrap">
                  <div className="about-intro-content">
                    <span className="about-subtext">ABOUT US</span>
                    <h3 className="about-subtitle">
                      We Are Increasing Business Success With Technology
                    </h3>

                    <p className="about-subdescription">
                      Over 25 years working in IT services developing software
                      applications and mobile apps for clients all over the
                      world.
                    </p>
                    <p className="about-subdescription">
                      We denounce with righteous indignation and dislike men who
                      are so beguiled and demoralized by the charms of pleasure
                      of the moment, so blinded by desire, that they cannot
                      foresee the pain and trouble that are bound to ensue; and
                      equal blame belongs to those who fail in their duty
                      through weakness of will, which is the same as saying.
                    </p>
                  </div>
                  <div className="get-started-mobile">
                    <GetStartedButton title="Learn More" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="about-working-process" id="about-working-process">
          <Container>
            <Row>
              <Col md={12}>
                <div className="about-intro-content text-center">
                  <span className="about-subtext">ABOUT US</span>
                  <h3 className="about-subtitle">Our Working Process</h3>
                </div>
              </Col>
            </Row>
            <div className="working-flow-paddingtop">

            <Row>
              {AccountsData.WORKING_FLOW.map((flowdata, flowid) => {
                return (
                  <Col key={flowid} md={3} lg={3} className="text-center mb-3">
                    <div>
                      <img
                        src={flowdata.image_url}
                        alt="vimkes"
                        className="img-fluid"/>
                    </div>
                    <div className="working-flow-title-wrap">
                          <h3 className="working-flow-title">{flowdata.title}</h3>
                      </div>

                  </Col>
                );
              })}
            </Row>
            </div>

          </Container>
        </section>
        
      <PricePlan priceplan={AccountsData.OUR_PLAN_DATA}/>
        


  
        <Footer />
      </section>
    );
  }
}
