import React, { Component } from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import { Container, Row, Col, } from "react-bootstrap";
import "./BlogDescription.css";
import HeaderImage from "../common/HeaderImageSection/HeaderImageSection";
import * as AccountsData from "../constant/AccountData/AccountData";
import Card from "../constant/Card/Card";


export default class BlogDescription extends Component {
  render() {
    return (
      <div>
        <Header />
        <HeaderImage
          title="Blogs"
          commonheaderinner="blogbackground"
          secondtitle="Blog"
        />

        <section className="blogs-list-section">
          <Container>
            <Row>
            <Col md={4}>
                  <div className="sticky-top">
                      
                
                  <Card title="Recent Post" recentBlog={AccountsData.BRAND_SLIDER_IMAGES} />
                  <Card title="Categories" categories={AccountsData.BRAND_SLIDER_IMAGES} />
                    
                  </div>
               
              </Col>
              <Col md={8}>
                  {
                      AccountsData.BRAND_SLIDER_IMAGES.map((blog_data,blogid)=>
                      {
                          return(

                       
                <div className="blog-card-section">
                  <div className="blog-image-wrapper">
                    <img
                      src="https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2021/01/b_4.jpg"
                      alt="vimkes"
                      className="img-fluid"
                    />
                    <div className="blog-image-inner">
                      <span>IT Service</span>
                    </div>
                  </div>

                  <div className="blog-calender">
                    <div>
                      <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                      <span>16 Nov 2021</span>
                    </div>

                    <div>
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                      <span>admin</span>
                    </div>
                  </div>

                  <div className="blog-content">
                    <h3>Servo Project Joins The Linux Foundation Fold Desco</h3>
                    <p>
                      We denounce with righteous indige nation and dislike men
                      who are so beguiled...
                    </p>
                  </div>

                  <div className="blog-learnButton">
                    <p>Learn More </p>
                    <span>
                      <i class="fa fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              )
            })
        }
              </Col>

            
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    );
  }
}
