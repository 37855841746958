import React from 'react';
import './GetstartedButton.css';
import {Link} from 'react-router-dom';



const GetStartedButton = (props) => {
    return (

        <div className="get-started-wrapper-blue">
            <p><Link to={props.naviagtoLink}>{props.title}</Link></p>
        </div>
       
    );
}

export default GetStartedButton;