import React, { Component } from 'react';
import { Container,Navbar,Nav } from 'react-bootstrap';
import images from '../../constant/Images/images';
import './Header.css';

export default class Header extends Component {
  render() {
    return (
      <Container className="nav-set">
    <Navbar expand="lg" className="container p-0">
     <Navbar.Brand href="/">
       <img src={images.header_logo} className="img-fluid" alt="Machine Craft" />
     </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto mr-auto">
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/About">About</Nav.Link>
      <Nav.Link href="/SoftwareDevelopment">Services</Nav.Link>
      <Nav.Link href="/BlogList">Blogs</Nav.Link>
      <Nav.Link href="/Contact">Contact</Nav.Link>
    </Nav>
  
  </Navbar.Collapse>
</Navbar>
      </Container>
    );
  }
}
