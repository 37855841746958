import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Blog.css";
import Slider from "react-slick";
import { BRAND_SLIDER_IMAGES } from "../../constant/AccountData/AccountData";

export default class Blog extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="blog-section" id="blog-section">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="text-center">
              <div className="blog-section-content">
                <h6 className="blog-section-title">BLOG</h6>
                <h3 className="blog-section-heading">Latest Tips &Tricks</h3>
                <p className="blog-section-des">
                  We've been building creative tools together for over a decade
                  and have a deep appreciation for software applications
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Slider {...settings}>
                {BRAND_SLIDER_IMAGES.map((brand_data, brandid) => {
                  return (
                    <div className="blog-card-section">
                      <div className="blog-image-wrapper">
                        <img
                          src="https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/blog_7.jpg"
                          alt="vimkes"
                          className="img-fluid"
                        />
                        <div className="blog-image-inner">
                          <span>IT Service</span>
                        </div>
                      </div>
                      <div className="blog-calender">
                        <div>
                          <i
                            class="fa fa-calendar-check-o"
                            aria-hidden="true"
                          ></i>
                          <span>16 Nov 2021</span>
                        </div>

                        <div>
                          <i class="fa fa-user-o" aria-hidden="true"></i>
                          <span>admin</span>
                        </div>
                      </div>

                      <div className="blog-content">
                        <h3>
                          Servo Project Joins The Linux Foundation Fold Desco
                        </h3>
                        <p>
                          We denounce with righteous indige nation and dislike
                          men who are so beguiled...
                        </p>
                      </div>

                      <div className="blog-learnButton">
                        <p>Learn More </p>
                        <span>
                          <i
                            class="fa fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
