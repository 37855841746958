import React from "react";
import images from "../Images/images";
import "./Card.css";
import { useHistory } from "react-router";
const Card = (props) => {

    function handletonavigate() {
     
        // useHistory.push("/");
      }
   
  return (
    <div className="card-wrap">
      <div className="card-content">
        <h6 className="recent-card-title">{props.title}</h6>
      </div>
      <div className="recent-card-main">
        {props.recentBlog && (
          <div>
            {props.recentBlog.map((recentdata, recentid) => {
              return (
                <div className="recent-card-content">
                  <img
                    src="https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2021/01/b_4.jpg"
                    className="img-fluid"
                    alt="Machine Craft"
                  />
                  <div className="recent-card-wrap" onClick={handletonavigate} >
                    <h6>Open Source Job Report Show More Openings Fewer </h6>
                    <p className="recent-celander">
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      <span>January 21, 2021</span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {
            props.categories &&
            <div>

         

        {props.categories.map((categories, id) => {
          return (
            <div className="recent-card-content">
              
              <div className="recent-card-wrap">
                <h6>Application Testing</h6>
                
              </div>
            </div>
          );
        })}
               </div>
        }
      </div>
    </div>
  );
};

export default Card;
